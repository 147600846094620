import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import anasuya from '../../../assets/images/BU_Leaders/anasuya.png';
class AnasuyaRay extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Alexander Komrakov" src={anasuya} />
                    </div>
                    <div className="details">
                        <h4>Anasuya Ray</h4>
                        <p>Vice President, Corporate Affairs South Asia</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={anasuya} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Anasuya Ray</h4>
                            <p className="sub-head">Vice President, Corporate Affairs South Asia</p>
                            <p className="content">Anasuya currently heads Corporate Affairs in South Asia for AB InBev and joined the company in 2021. She leads government affairs, public policy, regulatory affairs, community affairs and communications in South Asia. A focused professional, Anasuya drives growth through exceptional leadership skills while ensuring cost efficiencies, effective working capital management and maintaining strong relationships with key stakeholders.<br/><br/>With a career spanning over 25 years, Anasuya has led corporate affairs across leading global organisations, including Wrigley India, Samsung Electronics and Bain & Company. Most recently, Anasuya led the Corporate Affairs function for Mars, the world's leading manufacturer of chocolate, chewing gum, mints, and fruity confections in India.<br/><br/>Anasuya has completed an Executive Program in Economics of Mutuality from Saïd Business School, University of Oxford. She has a post-graduate degree in English Literature from Delhi University.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default AnasuyaRay;