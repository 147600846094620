import React from "react";
import our_commitment from '../../assets/images/diversity_inclusion/our_commitment.mp4';


class OurCommitment extends React.Component {
    render() {
        return(
            <div className="diversity-inclusion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="top-content-di aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                <h2>We are measured by the quality and diversity of our teams.</h2>
                                <p>Every individual at every level in our company is responsible for treating others with respect and cultivating, encouraging, maintaining, and preserving an inclusive environment. An inclusive environment is one where everyone is treated with respect and valued for their diverse perspectives and ideas.</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="di-list-table">
                                <div className="di-list-table-left aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="details">
                                        <h2>OUR <span>COMMITMENT</span>
                                        </h2>
                                        <p>Our purpose is to dream big to create a future of more cheers – and a world of more cheers can only be a more equal world. We believe our greatest strength is our diverse teams. Making diversity and inclusion in our organization a priority helps us achieve our dream, as it enables the diversity of thought needed to deliver better decision-making and achieve superior results. We know we have an important role to play in championing diversity and inclusion within AB InBev, as well as beyond it. Diversity &amp; Inclusion is embedded into how we think, behave, and operate. People at AB InBev have the responsibility to champion an equitable workplace and root out discrimination of any kind.</p>
                                    </div>
                                </div>
                                <div className="di-list-table-right aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="image">
                                        <video autoPlay className="d-i-video" loop="" width="400">
                                            <source src={our_commitment} type="video/mp4" />
                                            <source src="mov_bbb.ogg" type="video/ogg" /> Your browser does not support HTML video.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default OurCommitment;