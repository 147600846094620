import React from "react";
import principles_icon_1 from '../../assets/images/our_values/icon_1_values.png';
import principles_icon_2 from '../../assets/images/our_values/icon_2_values.png';
import principles_icon_3 from '../../assets/images/our_values/icon_3_values.png';
class OurPrinciples extends React.Component {
    render() {
        return(
            <section className="our-values-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="our-values-list">
                                    <ul>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <img src={principles_icon_1} alt="AB InBev We dream big" />
                                                <span className="dream">DREAM</span>
                                                <p className="second-circle">
                                                    <i>1</i>
                                                    <span>We dream big.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <img src={principles_icon_2} alt="AB InBev Our greatest strength is our people" />
                                                <span className="people">PEOPLE</span>
                                                <p className="second-circle">
                                                    <i>2</i>
                                                    <span>We are owners who think long-term.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <div className="image"></div>
                                                <span></span>
                                                <p className="second-circle">
                                                    <i>3</i>
                                                    <span>We are powered by great people and build diverse teams through inclusion and collaboration.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <img src={principles_icon_3} alt="AB InBev Culture" />
                                                <span className="culture">CULTURE</span>
                                                <p>
                                                    <i>4</i>
                                                    <span>We lead change and innovate for our consumers.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <div className="image"></div>
                                                <span></span>
                                                <p>
                                                    <i>5</i>
                                                    <span>We grow when our customers grow.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <div className="image"></div>
                                                <span></span>
                                                <p>
                                                    <i>6</i>
                                                    <span>We thrive when our communities thrive.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <div className="image"></div>
                                                <span></span>
                                                <p>
                                                    <i>7</i>
                                                    <span>We believe in simplicity and scalable solutions.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <div className="image"></div>
                                                <span></span>
                                                <p>
                                                    <i>8</i>
                                                    <span>We manage costs tightly and make choices to drive growth.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <div className="image"></div>
                                                <span></span>
                                                <p>
                                                    <i>9</i>
                                                    <span>We create and share superior value.</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li data-aos="fade-up" className="aos-init">
                                            <div className="list">
                                                <div className="image"></div>
                                                <span></span>
                                                <p>
                                                    <i>10</i>
                                                    <span>We never take shortcuts.</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default OurPrinciples;