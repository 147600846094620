import React from "react";
import principles_banner from '../../assets/images/our_values/icon_values.png';

class PrinciplesBanner extends React.Component {
    render() {
        return(
            <section className="our-values-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="our-values">
                                <p>AB InBev always dreams big. It’s our culture and our heritage. But more than that, it’s our future. A future that everyone can celebrate and everyone can share. A future with more cheers.</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="our-values-img">
                                <img src={principles_banner} alt="AB InBev Our Values" />
                                <div>
                                    <h4>OUR PURPOSE</h4>
                                    <p>We dream big to create a future with more cheers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default PrinciplesBanner;