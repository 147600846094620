import React from 'react';
import Banner from '../components/BUIndia/Banner';
import CorporateAffairs from '../components/BUIndia/CorporateAffairs';
import Finance from '../components/BUIndia/Finance';
import Heading from '../components/BUIndia/Heading';
import Marketing from '../components/BUIndia/Marketing';
import People from '../components/BUIndia/People';
import Procurement from '../components/BUIndia/Procurement';
import Sales from '../components/BUIndia/Sales';
import Solutions from '../components/BUIndia/Solutions';
import SupplyLogistics from '../components/BUIndia/SupplyLogistics';
import ViewAllJobsDreamBig from '../components/BUIndia/ViewAllJobsDreamBig';
import ViewMore from '../components/BUIndia/ViewMore';
import Layout2 from '../components/Layout/Layout2';

class BUIndia extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <Heading />
            <Finance />
            <CorporateAffairs />
            <Marketing />
            <People />
            <Procurement />
            <Sales />
            <Solutions />
            <SupplyLogistics />
            <ViewMore />
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default BUIndia;