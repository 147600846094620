import React from "react";
import Benefits from "../Common/Benefits";
import Initiatives from "../Common/Initiatives";
import Location from "../Common/Location";
class MoreAbout extends React.Component {
    render() {
        return(
            <div className="our-entities our-graduate-programs list-btm-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="heading-bu">
                                <h3 className="more-about">More About <span>GCC India</span></h3>
                            </div>
                        </div>
                        <Benefits />
                        {/* <Initiatives /> */}
                        <Location />
                    </div>
                </div>
            </div>
        );
    }
}
export default MoreAbout;