import React, {useState} from "react";
import operations_gcc from '../../assets/images/GCC_India/operations_gcc.jpeg';
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
class Operations extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            isOpen: false,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
            isOpen7: false,
        };
    
        // Your other methods and component code here
      }
    // state = {
    //     isOpen: false,
    //     isOpen1: false,
    //     isOpen2: false,
    //     isOpen3: false,
    //     isOpen4: false,
    //     isOpen5: false,
    //     isOpen6: false,
    //     isOpen7: false,
        
    // };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    openModal1 = () => this.setState({ isOpen1: true });
    closeModal1 = () => this.setState({ isOpen1: false });
    openModal2 = () => this.setState({ isOpen2: true });
    closeModal2 = () => this.setState({ isOpen2: false });
    openModal3 = () => this.setState({ isOpen3: true });
    closeModal3 = () => this.setState({ isOpen3: false });
    openModal4 = () => this.setState({ isOpen4: true });
    closeModal4 = () => this.setState({ isOpen4: false });
    openModal5 = () => this.setState({ isOpen5: true });
    closeModal5 = () => this.setState({ isOpen5: false });
    openModal6 = () => this.setState({ isOpen6: true });
    closeModal6 = () => this.setState({ isOpen6: false });
    openModal7 = () => this.setState({ isOpen7: true });
    closeModal7 = () => this.setState({ isOpen7: false });
    render() {
        // const [smShow, setSmShow] = useState(false);
        // const [lgShow, setLgShow] = useState(false);
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            
            <div className="teams-main-page bhq-india">
                <div className="teams-main-page-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-xs-12 col-sm-2">
                                <h2 className="head-rotate">Operations</h2>
                            </div>
                            <div className="col-md-10 col-xs-12 col-sm-10 carousel-teams">
                                <div className="details">
                                    <div className="image">
                                        <img alt="GCC India Operations Team" src={operations_gcc} />
                                    </div>
                                    <div className="details-main">
                                        <p>GCC India Operations manages the Finance, HR, and Commercial Operations for several AB InBev zones. The teams are transforming Operations through Tech and Analytics.</p>
                                    </div>
                                </div>
                                <div className="team-name-main">Operations Teams</div>
                                <Slider {...settings}>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>PEOPLE BUSINESS SERVICES</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">PEOPLE BUSINESS SERVICES</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our People Business Services (PBS) team drives Talent Acquisition, Onboarding, L&amp;D, Talent Management, Rewards &amp; Benefits, Employee Targets, Master Data Management, Payroll Inputs, Data Quality, Analytics, and much more.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal1}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>REST OF AFRICA</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen1} onHide={this.closeModal1} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">REST OF AFRICA</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Rest of Africa (ROA) team is a team of accounting professionals in ATR, PTP &amp; OTC domain. This team is transforming the finance operation for regions in Africa by leveraging GCC capabilities in analytics, digital transformation, and our domain expertise.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal2}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>PAYABLE MASTERS AND INSIGHTS</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen2} onHide={this.closeModal2} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">PAYABLE MASTERS AND INSIGHTS</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our payables teams are responsible for vendor payables, making sure our reputation improves in the market by ensuring that we pay our suppliers on time. This team adds value by controlling spent budget globally and bringing more value by putting the right controls to prevent spend leakage. Our master data teams create masters for customers, vendors, and materials, which are the base for any transactions done for finance.<br/><br/>Our Global Command Centre creates visibility to AB InBev’s top leadership around how our business is evolving and keeps track of core KPIs (key performance indicators)</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal3}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Accounts to Reconciliation</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen3} onHide={this.closeModal3} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Accounts to Reconciliation</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Accounts to Report (ATR) team is primarily responsible for most of the numbers in AB InBev’s reported financials that include balance sheets, profit and loss statements, and cash flow statements. This team makes use of cutting-edge technology and analytical tools to deliver these reports with the highest possible accuracy.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal4}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Customer Experience</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen4} onHide={this.closeModal4} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Customer Experience</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Customer Experience (CX) team ensures we stay committed to delivering a seamless experience in AB InBev’s customers’ life cycle – right from the time they place an order till the time they receive it, and even post that.<br/><br/>This team is an amalgamation of 3 functions – Customer Service Management, Order to Cash, and Sales Finance, with 190+ experts, cutting edge technology, and spread across 2 dynamic hubs of Bangalore and Prague.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal5}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Finance Performance &amp; Analytics</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen5} onHide={this.closeModal5} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Finance Performance &amp; Analytics</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Financial Planning and Analytics (FP&amp;A) team drives the budgeting, forecasting, and analytics processes that support AB InBev's financial health and business strategy. The FP&amp;A space combines in-depth analysis of both operational and financial data to align and evaluate business processes and strategies with financial goals. It relies heavily on financial analytics to measure and plan business operations and forecast their financial impact. The three sub-teams in the FP&amp;A structure – Global ZBB &amp; PPM, Global Supply &amp; KPI, and FP&amp;A Commercial together provide extensive coverage for various elements across the company’s P&amp;L (profit and loss), further enabled by a global outreach.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal6}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Commercial Performance &amp; Analytics</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen6} onHide={this.closeModal6} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Commercial Performance &amp; Analytics</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Commercial, Performance and Analytics (CP&amp;A) team is primarily responsible for insights, strategy, dashboarding, and analytics – which in turn results in business value for zones across AB InBev. This team’s top capabilities are marketing and data insights, tech sales, space planning, demand planning, category management, and much more. CP&amp;A builds Sales &amp; Marketing capabilities of the future to support our business team enabling them to focus on selling more beers, generating increased business value.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal7}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Digital Solutions</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen7} onHide={this.closeModal7} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Digital Solutions</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Digital Solutions team applies the concept of solving business problems using technology. This team brings in higher levels of automation and digitization, using process mining to identify opportunities, and to use Connected Planning technology to transform the way we consume data.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots">
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Business Transformation</span></div>
                                                {/* <div className="click-more">Know More</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Operations;