import React from 'react';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Church from '../../assets/images/our_history/Church.png';
import GermanBeerPurityLaw from '../../assets/images/our_history/GermanBeerPurityLaw_0.png';
import LabattBrewery from '../../assets/images/our_history/LabattBrewery.png';
import AnheuserBuschBrewingAssociation from '../../assets/images/our_history/AnheuserBuschBrewingAssociation.png';
import BackusandJohnstonBrewery from '../../assets/images/our_history/BackusandJohnstonBrewery.png';
import BrouwerijArtoisBrewery from '../../assets/images/our_history/BrouwerijArtoisBrewery.png';
import GrupoModeloLogo from '../../assets/images/our_history/GrupoModeloLogo.png';
import South_African_Breweries from '../../assets/images/our_history/South_African_Breweries.png';
import comingtogether from '../../assets/images/our_history/comingtogether.png';

const SectionWipes2Styled = styled.div`
    overflow: hidden;
    #pinContainer {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    #pinContainer .panel {
        height: 100vh;
        width: 100vw;
        position: absolute;
        text-align: center;
    }
    .panel span {
        position: relative;
        display: block;
        top: 50%;
        font-size: 80px;
    }
    .panel.blue {
        background-color: #3883d8;
    }
    .panel.turqoise {
        background-color: #38ced7;
    }
    .panel.green {
        background-color: #22d659;
    }
    .panel.bordeaux {
        background-color: #953543;
    }
    .panel-main {
        height: 100vh;
        width: 100vw;
        position: absolute;
    }
    .panel-main .panel-image {
        float: left;
        width: 50%;
        height: 100vh;
        padding: 10%;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .panel-main .panel-image img {
        width: 80%;
    }
    .panel-main .pane-content {
        float: left;
        width: 50%;
        height: 100vh;
        padding: 10% 10% 10% 10%;
        text-align: left;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .panel-main .pane-content p {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -.1px;
        line-height: 30px;
        margin-bottom: 17px;
    }
    .panel-main .panel-year {
        height: 100vh;
        width: 100vw;
        position: absolute;
        line-height: 100vh;
    }
    .panel-main .panel-year p {
        color: #e6b611;
        letter-spacing: -.1px;
        font-size: 78px;
        font-family: 'Avantt_semibold', sans-serif;
    }
    .panel-main .pane-content h3 {
        width: 100%;
        text-align: left;
        font-family: 'Avantt_semibold', sans-serif;
    }
`;

class HistoryImages extends React.Component {
    render() {
        return (
            <SectionWipes2Styled>
                <Controller>
                    <Scene triggerHook="onLeave" duration="300%" pin>
                        <Timeline wrapper={<div id="pinContainer" />}>
                            <section className="panel">
                                <div className='panel-main'>
                                    <div className='panel-image'>
                                        <img src={Church} alt="History" />
                                    </div>
                                    <div className='panel-year'>
                                        <p>1200</p>
                                    </div>
                                    <div className='pane-content'>
                                        <p>Over 800 years ago, AB InBev found its humble beginnings in the experienced brewing hands of Belgian monks. It was in their abbeys where one of our original beer brands, Leffe, came to be.</p>
                                        <p>Elsewhere in Belgium, devoted monks were experimenting with brewing techniques to create Hoegaarden, a rich and citrusy wheat beer. These brews were so delicious that they tempted the parishioners to skip church and instead revel in the smooth and subtle notes of the monks’ labor.</p>
                                    </div>
                                </div>
                            </section>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={GermanBeerPurityLaw} alt="GermanBeerPurityLaw" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>1500</p>
                                        </div>
                                        <div className='pane-content'>
                                            <p>The tradition of brewing wasn’t exclusive to Belgians. Germans were also busy making their mark on beer thanks to Reinheitsgebot — sometimes called “German Beer Purity Law.” These laws originated in Bavaria and limited beer ingredients to barley, hops, and water. In doing so, brewers cemented a uniquely German style of brewing.</p>
                                            <p>Even the English set aside their tea to begin brewing Bass beer in 1777 — which coincidentally became the world's first trademarked product.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={LabattBrewery} alt="LabattBrewery" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>1800</p>
                                        </div>
                                        <div className='pane-content'>
                                            <h3>Adopting & Adapting</h3>
                                            <p>As Europeans made their way to the new world, they brought their passion for beer in tow. In Canada, the Labatt Brewery connected areas like Toronto, Montreal, and the Maritimes over the same well-balanced and full-flavored beer.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={AnheuserBuschBrewingAssociation} alt="AnheuserBuschBrewingAssociation" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>1860</p>
                                        </div>
                                        <div className='pane-content'>
                                            <p>In 1860, in the beer-friendly city of St. Louis, Eberhard Anheuser took control of the Bavarian Brewery.</p>
                                            <p>After partnering with Adolphus Busch, they formed the Anheuser-Busch Brewing Association. Together, through their pioneering and innovating spirit, they built a refrigerated rail car system that spread Budweiser beer across the United States and eventually the world.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={BackusandJohnstonBrewery} alt="BackusandJohnstonBrewery" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>1879</p>
                                        </div>
                                        <div className='pane-content'>
                                            <p>Latin and South America embraced German and Austrian style lagers as their own. The largest brewery in Peru, the Backus and Johnston Brewery, was formed in 1879 by Jacob Backus and Howard Johnston. In Argentina, Cerveza Quilmes became something of a national symbol.</p>
                                            <p>Paraguay made its impact on brewing with a pale golden beer. Its delicate flavor and aroma were produced by Cervercería Paraguaya.</p>
                                            <p>East of Peru, Antarctica and Brahma were founded in Brazil. These crisp, clean pilsners would become the number one and number two most popular beers in all of Brazil and eventually merged together to create Ambev alongside Cervercería Paraguaya.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={BrouwerijArtoisBrewery} alt="BrouwerijArtoisBrewery" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>1900</p>
                                        </div>
                                        <div className='pane-content'>
                                            <h3>BELGIUM</h3>
                                            <p>Back in Belgium, the Brouwerij Artois brewery created a new way to celebrate Christmas — Stella Artois. Originally sold only in the winter season, this Christmas gift soon became a beloved year-round beer.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={GrupoModeloLogo} alt="GrupoModeloLogo" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>1900</p>
                                        </div>
                                        <div className='pane-content'>
                                            <h3>MEXICO</h3>
                                            <p>By the end of the 1900s, Mexico put its spin on traditional European techniques, creating a “special model” of beer — Modelo Especial. Grupo Modelo became the leader in Mexico’s beer production - combined with Corona, Pacífico, and other favorites.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={South_African_Breweries} alt="South_African_Breweries" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>1900</p>
                                        </div>
                                        <div className='pane-content'>
                                            <h3>AFRICA, AUSTRALIA, AND KOREA</h3>
                                            <p>The Dutch took their brewing expertise to Africa, founding South African Breweries (SAB) in the legendary headquarters of Castle Brewery. There, they served a growing market of miners and prospectors around Johannesburg. SAB eventually became the backbone of SABMiller. In Australia, Carlton & United Breweries (CUB) added their unique Australian twist — from golden ales to premium lagers to full-flavored stouts. Not to be left out, Oriental Brewery Co. found great success in the fresh, fizzy, and crisp tasting Cass — the number one beer brand in South Korea.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                            <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                                <section className="panel">
                                    <div className='panel-main'>
                                        <div className='panel-image'>
                                            <img src={comingtogether} alt="comingtogether" />
                                        </div>
                                        <div className='panel-year'>
                                            <p>2000</p>
                                        </div>
                                        <div className='pane-content'>
                                            <h3>COMING TOGETHER</h3>
                                            <p>Slowly these expert brewers grew together. Interbrew and Ambev merged to form InBev. Anheuser-Busch then joined, to create AB InBev.</p>
                                            <p>Around the world, more breweries like Mexico’s Grupo Modelo, South Korea’s Oriental Brewery, and SABMiller came to unite under the AB InBev umbrella. They all shared a common goal — bringing people together to form lasting relationships over great-tasting beer. With so many different cultural perspectives cultivated over centuries, we are uniquely positioned to create global change. And so, we continue to create tomorrow’s favorite flavors while still honoring our rich heritage of brewing.</p>
                                        </div>
                                    </div>
                                </section>
                            </Tween>
                        </Timeline>
                    </Scene>
                </Controller>
            </SectionWipes2Styled>
        );
    }
}

export default HistoryImages;