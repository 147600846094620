import React, {Component} from "react";
import Fancybox from "./fancybox.js";
import allychallenge from '../../assets/images/diversity_inclusion/allychallenge.png';
import women_day_5 from '../../assets/images/diversity_inclusion/women_day_5.png';
import women_day_6 from '../../assets/images/diversity_inclusion/women_day_6.png';
import women_day_7 from '../../assets/images/diversity_inclusion/women_day_7.png';
import women_day_1 from '../../assets/images/diversity_inclusion/women_day_1.png';
import women_day_2 from '../../assets/images/diversity_inclusion/women_day_2.png';
import women_day_3 from '../../assets/images/diversity_inclusion/women_day_3.png';
import womens_day from '../../assets/images/diversity_inclusion/womens_day.mp4';
import our_commitment from '../../assets/images/diversity_inclusion/our_commitment.mp4';


class AllDiverseSlates extends React.Component {
    render() {
        return(
            <div className="all-diverse-slates">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3>CELEBRATING ALL DIVERSE SLATES</h3>
                            <Fancybox options={{ dragToClose: false }}>
                                <p className="main-img-slider">
                                    <a className="image-fancy" data-fancybox="gallery" href={our_commitment}>
                                        <img src={allychallenge} />
                                    </a>
                                    <a className="image-fancy" data-fancybox="gallery" href={women_day_5}>
                                        <img src={women_day_5} />
                                    </a>
                                    <a className="image-fancy" data-fancybox="gallery" href={women_day_6}>
                                        <img src={women_day_6} />
                                    </a>
                                    <a className="image-fancy" data-fancybox="gallery" href={women_day_7}>
                                        <img src={women_day_7} />
                                    </a>
                                    <a className="image-fancy" data-fancybox="gallery" href={womens_day}>
                                        <img src={women_day_1} />
                                    </a>
                                    <a className="image-fancy" data-fancybox="gallery" href={women_day_2}>
                                        <img src={women_day_2} />
                                    </a>
                                    <a className="image-fancy" data-fancybox="gallery" href={women_day_3}>
                                        <img src={women_day_3} />
                                    </a>
                                </p>
                            </Fancybox>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AllDiverseSlates;