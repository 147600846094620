import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import vineet from '../../../assets/images/BU_Leaders/vineet.png';
class VineetSharma extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Alexander Komrakov" src={vineet} />
                    </div>
                    <div className="details">
                        <h4>Vineet Sharma</h4>
                        <p>Vice President Marketing – South Asia, AB InBev</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={vineet} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Vineet Sharma</h4>
                            <p className="sub-head">Vice President Marketing – South Asia, AB InBev</p>
                            <p className="content">Vineet Sharma currently heads Marketing and New Business Development – South Asia, at AB InBev and has been with the company for nearly five years. During this time, Vineet has held key positions as Associate Marketing Director for Budweiser and most recently, the Trade Marketing Director for South Asia.<br/><br/>As the Associate Marketing Director for Budweiser, Vineet has been instrumental in shaping the marketing strategy for Budweiser portfolio in South Asia, through innovative campaigns and initiatives that have fueled the top and bottom-line growth. A growth- focused marketer, in his role as the Trade Marketing Director, Vineet helped define the strategic roadmap for the company to create impactful in-trade programs, backed by technological innovations and relevant customer & consumer trends. Leveraging more than a decade of experience in Marketing and Sales, his purpose-driven vision anchored on strong consumer and customer insights has led to several category-leading initiatives such as the 360 Budweiser campaigns for the FIFA World Cup 2018, Music and Equity campaign along with the launch of innovative Digital Trade initiatives such as Bud4Trade & Digital Menu which have delivered strong commercial results. He possesses strong leadership skills and has a proven track record in managing high-performance teams across the region.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default VineetSharma;