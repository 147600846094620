import React from "react";
class LocationsList extends React.Component {
    render() {
        return(
            <div className="our-locations">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="locations-list-show aos-init" data-aos="fade-up" data-aos-duration="1000">
                                <div className="locations-details">
                                    <div className="image">
                                        <iframe allowfullscreen="" height="255" loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.816883302125!2d77.6917603145191!3d12.98356111812933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1177546c4d71%3A0x760c9ca512a1ab6d!2sAB%20In%20Bev%20GCC!5e0!3m2!1sen!2sin!4v1638367643922!5m2!1sen!2sin" width="100%"></iframe>
                                    </div>
                                    <div className="address">
                                        <h3>GCC</h3>
                                        <p>AB InBev India, 11th, 12th &amp; 13th Floor, Bagmane World Technology Center SEZ, K.R.Puram Marathahalli Ring Road, Laxmi Sagar Layout, Mahadevapura, Bengaluru, Karnataka 560048</p>
                                        <a href="mailto:career.gccindia@ab-inbev.com">CONTACT US</a>
                                    </div>
                                    <div className="map-show">
                                        <div id="map-gcc">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                            <div className="locations-list-show aos-init" data-aos="fade-up" data-aos-duration="2000">
                                <div className="locations-details">
                                    <div className="image">
                                        <iframe allowfullscreen="" height="255" loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3086.970077912711!2d77.59885768545305!3d12.970166540869368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1679003a34d3%3A0x795a66866802c3e0!2sABInBev%20Growth%20Analytics%20Center%20(GAC)!5e0!3m2!1sen!2sin!4v1638367524487!5m2!1sen!2sin" width="100%"></iframe>
                                    </div>
                                    <div className="address">
                                        <h3>GAC</h3>
                                        <p>8, Vittal Mallya Rd, Shanthala Nagar, Ashok Nagar, Bengaluru, Karnataka 560001</p>
                                        <a href="mailto:career.gccindia@ab-inbev.com">CONTACT US</a>
                                    </div>
                                    <div className="map-show">
                                        <div id="map-gac">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default LocationsList;