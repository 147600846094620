import React from "react";
class SustainabilityDetails extends React.Component {
    render() {
        return(
            <div className="sustainability-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <ul>
                                <li>
                                    <h3>Sustainability Is Our Business</h3>
                                    <p>In 2017, we formally engaged stakeholders through our materiality review process and the results informed our 2025 Sustainability Goals. Since our 2017 assessment, our business and the world have evolved tremendously. Therefore, in early 2020 we conducted a new materiality assessment to reflect the aspects most material to our stakeholders in order to continue to focus on critical economic, social, and environmental issues as a business and community partner.</p>
                                </li>
                                <li>
                                    <h3>Our 2020 Materiality Assessment</h3>
                                    <p>In 2020, the materiality assessment process* mapped key economic, social, and environmental issues on a matrix based on the degree of stakeholder interest and potential business impact. External stakeholders include civil society/non-governmental organizations (NGOs), customers (retailers) and consumers, regulators, business partners, suppliers, and investors. This matrix illustrates the view of internal and external stakeholders interviewed, with the rankings helping to inform the evolution of our sustainability and responsibility strategy. It is important to highlight that we do not view these issues in isolation, as in many cases they are interconnected.</p>
                                </li>
                                <li>
                                    <h3>Sustainability Governance</h3>
                                    <p>The Global Sustainability team reports twice a year to the Finance Committee of the Board of Directors to review topics relating to supply chain security and sustainability. <br />
                                        <br />We also have established a Sustainability Council led by our Chief Procurement and Sustainability Officer and is comprised of the Chief Legal &amp; Corporate Affairs Officer, Chief Supply Officer, Chief Marketing Officer, Chief Finance Officer, and Chief People Officer. The Sustainability Council meets monthly to oversee the achievement of our goals and to progress our broader sustainability strategy. Bi-monthly updates are provided to the CEO.
                                    </p>
                                </li>
                                <li>
                                    <h3>External Engagement</h3>
                                    <p>We regularly consult with an external group of experts as environmental advisors, as well as other key NGOs, to bring in a diverse perspective and to advise on decision - making, target setting, and program strategies and implementation across the four pillars of our Sustainability Goals. Additionally, we leverage CDP to manage our climate and water governance. Below are our most recent submissions for the CDP investor questionnaires:</p>
                                    <p><a href="https://www.ab-inbev.com/img/redesign/Sustainablity/CDP-response-Climate-2021.pdf" target="_blank"><i>AB InBev CDP Climate Response 2021 </i></a></p>
                                    <p><a href="https://www.ab-inbev.com/img/redesign/Sustainablity/CDP-Water-Response-2021.pdf" target="_blank"><i>AB InBev CDP Water Response 2021 </i></a></p>
                                    <p>To continue strengthening a collaborative approach to sustainable development, we engage in industry associations and organizations such as the World Business Council for Sustainable Development (WBCSD), the World Economic Forum (WEF), the Beverage Industry Environmental Roundtable (BIER), AIM-Progress, BSR, the Climate Group, RE100, the 2030 Water Resources Group, and Sustainable Food Lab, as well as other regional and sector-specific associations.</p>
                                    <i>Remuneration linked to Sustainability </i>
                                    <p>Sustainability-related targets tied to annual variable compensation are cascaded from the highest level of our company to all operating units.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SustainabilityDetails;