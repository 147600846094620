import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import sajith from '../../../assets/images/GCC_Leaders/Sajith-01.png';
class SajithKurup extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Sajith Kurup" src={sajith} />
                    </div>
                    <div className="details">
                        <h4>Sajith Kurup</h4>
                        <p>Global Director, Analytics</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={sajith} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Sajith Kurup</h4>
                            <p className="sub-head">Global Director, Analytics</p>
                            <p className="content">Sajith Kurup has over 15 years of experience across five different countries and three different organizations in Analytics, Marketing & Shopper Insights, Sales, and Leadership Development. Sajith has a master’s degree in marketing and a bachelor’s degree in mathematics. 

                                <br/><br/>He has been with AB InBev for over 6 years, and in his current role, he leads the global commercial analytics team. The objective of the team is to ensure maximum ROI on the $4+ billion sales & marketing spend and brand relevance to our consumers and customers through AI- and ML-driven decision product ecosystem. Prior to AB InBev, he worked in STG and Genpact. He is also on the client advisory board of analytics firms in India.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default SajithKurup;