import React from 'react';
import HistoryDetails from './HistoryDetails';
import HistoryImages from './HistoryImages';
import HistoryYear from './HistoryYear';

class History extends React.Component {
  render() {
    return (
        <div>
            {/* <div class="history-images"> */}
                <HistoryImages />
            {/* </div> */}
            {/* <div class="history-year"> */}
                {/* <HistoryYear/> */}
            {/* </div> */}
            {/* <div class="history-details"> */}
                {/* <HistoryDetails /> */}
            {/* </div> */}
        </div>
    );
  }
}

export default History;