import React from "react";
import procurement from '../../assets/images/BU_India/procurement.jpeg';
class Procurement extends React.Component {
    render() {
        return(
            <div className="bu-common-bu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="bu-common-main">
                                <div className="bu-left">
                                    <h4 className="d-block d-sm-none">Procurement</h4>
                                    <div className="image">
                                        <img alt="Procurement" src={procurement} width="100%" />
                                    </div>
                                </div>
                                <div className="bu-right">
                                    <h4 className="d-none d-sm-block">Procurement</h4>
                                    <p>Our Procurement team selects vendors, negotiates contracts, and purchases goods. They drive our sustainability agenda across our business and with our supply base in order to achieve our 2025 goals on climate action, circular packaging, water stewardship &amp; smart agriculture.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Procurement;