import React from 'react';
import PrivacyContentSection from './PrivacyContentSection'

const TermsContent = () => {
    const member_accounts = {
        header: 'Member Accounts',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'In order to use certain features of the Platform, you may need to create an account ("User Account"). If the Platform requires you to create a User Account, you may never use another User’s Account without permission. All content posted by you via your User Account must comply with the applicable Indian laws.'
    }

    const intellectual_property = {
        header: 'Intellectual Property Ownership',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'Unless otherwise noted, all text, content and documents on the Platform, any names, logos, trademarks, service marks, brand identities, characters, trade names, graphics, designs, copyrights, trade dress, or other intellectual property appearing in the Platform, and the organization, compilation, look and feel, illustrations, artwork, videos, music, software and other works on the Platform (the "Content") are owned by ABI (or its affiliates) or used with permission or under license from a third party (hereinafter collectively referred to as the "Owner") and are protected under copyright, trademark and other intellectual property and proprietary rights laws. As between ABI and you, all right, title and interest in and to the Content will at all times remain with ABI and/or its Owners. All brand names, product names, titles, slogans, logos, or service names and other marks used on the Platform, are registered and/or common law trade names, trademarks or service marks of ABI.'
    }

    const limited_use = {
        header: 'Limited Use; Restrictions on Use',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'You are permitted to use the Content and/or any services and products on the Platform for lawful purposes as provided in the Terms and Conditions only; any other use or misuse of any Content is strictly prohibited. ABI grants you a non-exclusive, limited, personal, non-transferable, revocable, license to access and use the Content, without right to sublicense, under the following conditions: you shall not, without ABI’s express written consent: (a) copy, retransmit, modify, disseminate, display, perform, reuse, re-post, broadcast, circulate, or otherwise distribute the Content, or modify or re-use all or part of the Content, (b) use any tradename, trademark, or brand name of ABI in metatags, keywords and/or hidden text, (c) create derivative works from the Content or commercially exploit the Content, in whole or in part, in any way, and (d) use the Platform, the Content, and/or any portion thereof, in any manner that may give a false or misleading impression, attribution or statement as to ABI, the Owner, or any third party referenced therein. ABI reserves all other rights. You shall not alter, remove or obscure any copyright notice, digital watermarks, proprietary legends or any other notice included in the Content. Except as expressly provided herein, nothing on the Platform shall be construed as conferring any license under ABI’s and/or its Owner’s intellectual property rights, whether by estoppel, implication or otherwise. Notwithstanding anything herein to the contrary, ABI may revoke any of the foregoing rights and/or your access to the Platform, or any part thereof, including the blocking of your IP Address, at any time without prior notice.'
    }

    const social_media = {
        header: 'Social Media Sites and Third Party Links',
        header_desc:'',
        body: [],
        note: '',
        footer_desc: (
            `
                From time to time, the Platform may include features and functionality that allow you to interact with other sites that are not under our control ("Linked Site"), including various social media websites ("Social Media Sites"). ABI provides these features, functionality and links to you only as a convenience and does not endorse any Linked Sites or Social Media Sites. ABI is not responsible for the contents or transmission of any Linked Site or Social Media Site or for the terms of use or privacy practices of any Linked Site of Social Media Site. You should carefully read the policies of any site you visit. Also, in the event that you use any Social Media Site to comment upon ABI or any of its products, you agree that you will always clearly and conspicuously disclose any material connection you have with ABI (if any) or any consideration you may receive from ABI in connection with your comment (if any). Under no circumstances are you authorized to make any claim regarding ABI or any of its products on any Social Media Site regardless of any material connection you may have with ABI or your receipt of any consideration.<b>IF YOU MAKE ANY CLAIM REGARDING ABI OR ANY OF ABI’S PRODUCTS ON A SOCIAL MEDIA SITE IN VIOLATION OF THE FOREGOING, YOU, AND NOT ABI, SHALL BE THE SOLE AUTHOR OF SUCH CLAIM AND SHALL BE SOLELY LIABLE THEREFORE.</b>
            `
        )
    }

    const submitted_ideas = {
        header: 'Submitted Ideas',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'While ABI appreciates your interest, we expressly request that you do not submit any ideas, suggestions, concepts, techniques, procedures, methods, systems, designs, plans, charts, or similar materials (collectively “Submitted Ideas”). All Submitted Ideas will be subject to the policies published on ABI Website. We ask that you never submit an idea that you consider to be confidential and/or proprietary. All Submitted Ideas disclosed or offered to us by you shall be deemed to be non-confidential and non-proprietary and shall become the exclusive property of ABI. Further, you understand and acknowledge that ABI employs both internal and external resources which may have developed or may in the future develop ideas identical or similar to your Submitted Ideas and that ABI is only willing to consider the suggestion on these terms. In any event, you acknowledge and agree that ABI assumes no obligation of confidentiality or nondisclosure, express or implied by considering your Submitted Ideas. Without limitation, ABI shall be entitled to unrestricted use of the Submitted Ideas (in whole or in part and including, without limitation, any derivations thereof) for any purpose whatsoever, commercial or otherwise without any form of compensation.'
    }

    const community_guidelines = {
        header: 'Community Guidelines',
        header_desc: (
            `
                In addition to links to Social Media Sites, the Platform itself may include bulletin boards, blogs, chat rooms, comments sections, and other community forums which allow you to post information, provide feedback and comments, and otherwise interact with other users, either through postings or by interacting in real-time (together with the ABI relevant sections of the Social Media Sites, the "Community Forums"). If the Platform includes any Community Forums, the restrictions and obligations contained in this Section shall apply.
                <br />
                <br />
                ABI shall monitor the Community Forums on a regular basis for any readily apparent violations of these Terms and Conditions or illegal content. Notwithstanding the foregoing, you acknowledge that ABI is under no obligation to edit or modify any information available in a Community Forum or decide any dispute or disagreement between posters and shall have no liability to you for any content posted in a Community Forum.
                <br />
                <br />
                You acknowledge that any opinions, statement, recommendation, offers, advice or other information presented or disseminated on the Community Forums are those of their respective authors who are solely responsible and liable for their content. ABI reserves the right, in its sole discretion, to refuse to post or remove any material submitted or posted on the Community Forums.
                <br />
                <br />
                By using this site, you: (a) authorize ABI to collect and store any comments, images, or other content that you upload, post, or submit ("Submissions") on our servers and systems in accordance with our <a href="/privacy-policy">Privacy Policy</a>, (b) grant ABI an unlimited, perpetual, royalty-free, sub-licensable, transferable and irrevocable license to use, modify, or adapt the Submissions for any purpose whatsoever, including but not limited to incorporating the Submissions into Content that may be commercial in nature. In addition, since information on this site is public and for every user to access, you acknowledge that you do not have any expectation of privacy in relation to your Submissions. Finally, you are expressly prohibited from submitting any of the following ("Prohibited Submissions"):
            `
        ),
        body: [
            {
                'li_header':'',
                'li_body':'Any Submission that promotes drinking and driving or irresponsible consumption of alcohol, disparages competitive products, is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, racially offensive, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable'
            },
            {
                'li_header':'',
                'li_body':'Any Submission that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law, including, without limitation, material that depicts child-pornography, acts of violence, drug use or would violate the Indian law'
            },
            {
                'li_header':'',
                'li_body':'Any Submission that may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party'
            },
            {
                'li_header':'',
                'li_body':'Any Submission that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity'
            },
            {
                'li_header':'',
                'li_body':'Unsolicited promotions, political campaigning, advertising or solicitations'
            },
            {
                'li_header':'',
                'li_body':'Private information of any third party, including, without limitation, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers'
            },
            {
                'li_header':'',
                'li_body':'Viruses, spyware, trojans, corrupted data or any other harmful, disruptive or destructive files; or'
            },
            {
                'li_header':'',
                'li_body':'Any Submission that in our sole judgment is inappropriate or objectionable or which restricts or inhibits any other person from using or enjoying the Site, or which may expose ABI or its affiliates or its users to any harm or liability of any type.'
            },
        ],
        note: '',
        footer_desc:''
    }

    const no_archive = {
        header: 'No Archive',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'Even if this Platform includes features and functionality that permit you to upload certain Submissions to our services or systems, this Platform is not and shall not function as an archive. ABI shall have no liability to you or any other person for loss, damage, or destruction to your Submission. You shall be solely responsible for maintaining independent archival and backup copies of any Submission.'
    }

    const disclaimers = {
        header: 'Disclaimers',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'ABI reserves the right to change any part of the Platform at any time without notice, subject to the “NOTICE” provision set forth below.'
    }

    const warrenty_limitations = {
        header: 'Warranties, Limitation of Liability',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:(
            `
                THIS PLATFORM IS PROVIDED ON AN "AS IS, AS AVAILABLE" BASIS. NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THOSE OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ARE MADE WITH RESPECT TO THIS PLATFORM OR ANY INFORMATION OR SOFTWARE THEREIN. Neither ABI, its affiliates, nor any of their respective officers, directors, agents or other representatives will be liable for any damages, direct, indirect, incidental, consequential, special, or punitive, including, without limitation, loss of data, income, profit or goodwill, loss of or damage to property and claims of third parties arising out of your access to or use of (or inability to use) the Platform, or arising out of any action taken in response to or as a result of any Content or other information available on the Platform, however caused, whether based on breach of contract, tort, proprietary rights infringement, product liability or otherwise—except in the event ABI fails to take reasonable security precautions as described in our <a href="/privacy-policy">Privacy Policy</a> or is otherwise negligent. The foregoing shall apply even if ABI was advised of the possibility of such damages. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES; AS A RESULT, THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. If you become dissatisfied in any way with the Platform Terms and Conditions or Privacy Policy, your sole and exclusive remedy is to stop your use of the Platform and its services. You hereby waive any and all claims against ABI and its affiliates, agents, representatives and licensors arising out of your use of the Platform—except in the event ABI fails to take reasonable security precautions as described in our <a href="/privacy-policy">Privacy Policy</a> or is otherwise negligent.
            `
        )
    }

    const copyright_act = {
        header: 'Copyright Act, 1957',
        header_desc:'Where applicable under applicable laws, ABI is committed to respecting and protecting the legal rights of copyright owners. As such, ABI adheres to the following notice and take down policy. If you believe any of the Content infringes upon your intellectual property rights, please submit a notification alleging such infringement (hereafter a "Notice"). To be valid, a Notice must (i) be provided to ABI’s designated officer, ("Copyright officer"), as set forth below, and (ii) include the following:',
        body: [
            {
                'li_header':'',
                'li_body':'A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed'
            },
            {
                'li_header':'',
                'li_body':'Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works'
            },
            {
                'li_header':'',
                'li_body':'Identification of the material claimed to be infringing or to be the subject of infringing activity and that is to be removed or access disabled and information reasonably sufficient to permit the service provider to locate the material'
            },
            {
                'li_header':'',
                'li_body':'Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an electronic mail'
            },
            {
                'li_header':'',
                'li_body':'A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and'
            },
            {
                'li_header':'',
                'li_body':'A statement that, under penalty of perjury, the information in the notification is accurate and you are authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.'
            },
        ],
        note: '',
        footer_desc: (
            `
            ABI’s Copyright Agent to receive Notices is: email: <a href="mailto:sumit.singh@ab-inbev.com">sumit.singh@ab-inbev.com</a> . For clarity, only Notices should go to the Copyright officer; any other feedback, comments, online purchases or other communications should be directed to the applicable customer service links posted on the Platform. You acknowledge that in order for ABI to be authorized to takedown any Content, your Notice must comply with all of the requirements of this Section.
            `
        )
    }

    const no_framing = {
        header: 'No Framing; Links; Third Party Sites',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'Framing, in-line linking or other methods of association with the Platform are expressly prohibited without prior written approval from ABI.'
    }

    const accept_ability = {
        header: 'Ability to Accept Terms and Conditions',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'You affirm that you have the legal capacity and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in the Terms and Conditions, and to abide by and comply with the Terms and Conditions.'
    }

    const assignment = {
        header: 'Assignment',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'The Terms and Conditions, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by ABI without restriction.'
    }

    const notice = {
        header: 'Notice',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:'From time to time, we may revise these Terms and Conditions. To help you stay current of any changes, ABI may take one or more of the following steps: (1) ABI notes the date the Terms and Conditions was last updated above, and (2) when ABI makes a material change to the Terms and Conditions, we may post conspicuous announcements of such changes on the Platform next to the link to these Terms and Conditions. Your use of the Platform following the posting of any revised Terms and Conditions shall be deemed acceptance of the revised Terms and Conditions. ABI strongly recommends checking the Terms and Conditions periodically. If we are required by law to obtain your express consent for any changes to the Terms and Conditions, then we will make a commercially reasonable attempt to obtain your consent before implementing such revisions.'
    }

    const governing_law = {
        header: 'Governing law',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:(
            `
                These Terms and Conditions, the use of the Platform, services and/or any contractual or non-contractual (including pre-contractual) matters in connection with their conclusion, validity, interpretation, enforcement, performance and termination shall be governed by and construed in accordance with the laws of India, to the extent that the laws of India are not overridden by applicably mandatory laws, e.g. consumer protection laws you would mandatorily benefit from.
                <br />
                Without prejudice to your mandatory rights under applicable laws to refer any claims to other competent courts, any dispute arising out of or in connection with these Terms and Conditions, the use of the Platform, services and/or any contractual or non-contractual (including pre-contractual) matters in connection with their conclusion, validity, interpretation, enforcement, performance and termination will be submitted to the exclusive jurisdiction of the courts of India.
            `
        )
    }

    const miscellaneous = {
        header: 'Miscellaneous',
        header_desc:'',
        body: [],
        note: '',
        footer_desc:(
            `
                ABI’s failure to enforce any provision of the Terms and Conditions shall not be deemed a waiver of such provision nor of the right to enforce such provision. A printed version of the Terms and Conditions and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to the Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
                <br />
                <br />
                If you have any questions or comments regarding these Terms and Conditions, please contact us by using our Indian “contact us” website (<a href="https://contactus.ab-inbev.com">https://contactus.ab-inbev.com</a>).
                <br />
                <br />
                Thank you for visiting our site.
            `
        )
    }

    return(
        <div className='privacy_container'>
            <div className='container'>
                <div className='privacy_header'>
                    TERMS AND CONDITIONS
                </div>
                <div className='privacy_content'>
                    <div className='header'>
                        Contents
                    </div>
                    <div className='privacy_list'>
                        <a href='#member_accounts'>Member Accounts</a>
                        <br />
                        <a href='#intellectual_property'>Intellectual Property Ownership</a>
                        <br />
                        <a href='#limited_use'>Limited Use; Restrictions on Use</a>
                        <br />
                        <a href='#social_media'>Social Media Sites and Third Party Links</a>
                        <br />
                        <a href='#submitted_ideas'>Submitted Ideas</a>
                        <br />
                        <a href='#community_guidelines'>Community Guidelines</a>
                        <br />
                        <a href='#no_archive'>No Archive</a>
                        <br />
                        <a href='#disclaimers'>Disclaimers</a>
                        <br />
                        <a href='#warrenty_limitations'>Warranties; Limitation of Liability</a>
                        <br />
                        <a href='#copyright_act'>Digital Millennium Copyright Act</a>
                        <br />
                        <a href='#no_framing'>No Framing; Links; Third Party Sites</a>
                        <br />
                        <a href='#accept_ability'>Ability to Accept Terms and Conditions</a>
                        <br />
                        <a href='#assignment'>Assignment</a>
                        <br />
                        <a href='#notice'>Notice</a>
                        <br />
                        <a href='#governing_law'>Governing law</a>
                        <br />
                        <a href='#miscellaneous'>Miscellaneous</a>
                    </div>

                </div>
                <div className='privacy_description'>
                    Thank you for visiting our website, mobile website, or mobile application (collectively, the "Platform"). This privacy policy (the "Privacy Policy") is designed to assist you in understanding how <b>GCC SERVICES INDIA PRIVATE LIMITED (“ABI or GCC”)”)</b>, a company incorporated under Companies Act, 2013 adhering to India laws, having its registered office at Peridot Block, 11th,12th &13th floor, Bagmane World Technology Centre SEZ, K. R. Puram, Marathahalli, Ring Road Bangalore - 560048, registered with the Corporate Identification Number: U74900KA2014FTC077722 (“ABI”, “GCC”, “we”, “our” or “us”). Your use and access of the Platform is governed by and subject to the following Terms and Conditions. If you do not agree to these terms, or if you do not agree with our Privacy Policy please do not use the Platform or any services offered by the Platform.
                    <br />
                    <b>
                        BY ENTERING ACCESSING, BROWSING, SUBMITTING INFORMATION TO, OR OTHERWISE USING THIS WEBSITE, YOU ACKNOWLEDGE AND AGREE TO THE FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THIS WEBSITE.
                    </b>
                </div>

                <PrivacyContentSection data={member_accounts} id="member_accounts"/>

                <PrivacyContentSection data={intellectual_property} id="intellectual_property"/>

                <PrivacyContentSection data={limited_use} id="limited_use"/>

                <PrivacyContentSection data={social_media} id="social_media"/>

                <PrivacyContentSection data={submitted_ideas} id="submitted_ideas"/>

                <PrivacyContentSection data={community_guidelines} id="community_guidelines"/>

                <PrivacyContentSection data={no_archive} id="no_archive"/>

                <PrivacyContentSection data={disclaimers} id="disclaimers"/>

                <PrivacyContentSection data={warrenty_limitations} id="warrenty_limitations"/>

                <PrivacyContentSection data={copyright_act} id="copyright_act"/>

                <PrivacyContentSection data={no_framing} id="no_framing"/>

                <PrivacyContentSection data={accept_ability} id="accept_ability"/>

                <PrivacyContentSection data={assignment} id="assignment"/>

                <PrivacyContentSection data={notice} id="notice"/>

                <PrivacyContentSection data={governing_law} id="governing_law"/>

                <PrivacyContentSection data={miscellaneous} id="miscellaneous"/>
            </div>
        </div>
    )
}

export default TermsContent;