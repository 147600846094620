import React from "react";
class MainContent extends React.Component {
    render() {
        return(
            <div>
                <div className="common-programme-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <h3>What is it?</h3>
                                <p>Our Supply Management Traineeship (SMT) is all about brewing, packaging, and delivery. The focus is on improving processes and efficiencies in our breweries and our logistics/supply chain – all to help us stay ahead of the pack. It’s where you can make a significant impact on the environment and drive innovation.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <h3>What does it include?</h3>
                                <p>Experience life on the front line of a brewery, working with our operators over 6 months to make the great beers we're famous for. But you won't just be observing. You'll make a direct impact – addressing real challenges, creating innovative solutions, and helping to grow our business. Through challenging projects at multiple locations and departments, you’ll get a broad view of our supply and a unique experience in our operations business. Impress us, and you'll soon be leading a team of your own. <br />
                                    <br /> You’ll also be able to build your leadership, project, and general management skills. You'll not only learn the technical and practical challenges of our business, but also have the opportunity to improve or solve them. This is a path that could take you all around India or even the world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <h3>Who is it for?</h3>
                                <p>If you love being front and center of all the action, the SMT has your name all over it. You’ll get a kick from working fast, and love working within a team. You’ll be results-driven, a true leader of the pack. <br />
                                    <br /> To be eligible for the SMT, you’ll have undertaken some form of a leadership position in the past, and you’ll need to have graduated before August 2022 with a Bachelor’s or Master’s (or equivalent) degree in either:
                                </p>
                                <ul>
                                    <li>Engineering (Chemical, Mechanical, Production, or Electrical)</li>
                                    <li>Science (Bio-chemistry, Micro-biology or Brewing/Food science)</li>
                                    <li>Other relevant Degrees(Supply Chain, Logistics, Business, etc.)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <p className="text-center">Here’s what to expect:</p>
                                <ul className="selection-process-new">
                                    <li>
                                        <div className="right-content">
                                            <span>1</span>
                                            <h4>Online <br /> Application </h4>
                                            <p>Applications are open from September 1st to November 30th. With places expected to fill up fast, be sure to get your application in early to avoid disappointment!</p>
                                        </div>
                                        <div className="line-left">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-address-card fa-w-18" data-fa-i2svg="" data-icon="address-card" data-prefix="fa" focusable="false" role="img" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="line-right">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-desktop fa-w-18" data-fa-i2svg="" data-icon="desktop" data-prefix="fa" focusable="false" role="img" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                        <div className="left-content">
                                            <h4>Online <br /> Assessments </h4>
                                            <span>2</span>
                                            <p>If your application is successful, you'll then be invited to complete an online assessment that will help us get to know you and which areas of the business you're best suited for.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="right-content">
                                            <span>3</span>
                                            <h4>Video <br /> Interview </h4>
                                            <p>Next, you'll participate in a video exercise where you will be presented with a business case. Through a recorded video, you'll get a chance to show your creative and communication skills.</p>
                                        </div>
                                        <div className="line-left">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-play fa-w-14" data-fa-i2svg="" data-icon="play" data-prefix="fa" focusable="false" role="img" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="line-right">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-users fa-w-20" data-fa-i2svg="" data-icon="users" data-prefix="fa" focusable="false" role="img" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                        <div className="left-content">
                                            <h4>IT'S TIME TO <br /> MEET YOU IN PERSON! </h4>
                                            <span>4</span>
                                            <p>Our assessment day is a mix of face-to-face interviews, group exercises, and work situational insights. You'll also meet some current AB InBev employees, so you can find out more about the role and company culture.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="right-content">
                                            <span>5</span>
                                            <h4>FINAL <br /> ROUNDTABLE </h4>
                                            <p>If you've wowed us so far, you'll be invited to your final group interview with our senior leaders at the organization.</p>
                                        </div>
                                        <div className="line-left">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-users fa-w-20" data-fa-i2svg="" data-icon="users" data-prefix="fa" focusable="false" role="img" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="sixth-list">
                                        <div className="line-right">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-envelope fa-w-16" data-fa-i2svg="" data-icon="envelope" data-prefix="fa" focusable="false" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                        <div className="left-content">
                                            <h4>Offer</h4>
                                            <span >6</span>
                                            <p>If we're the right fit for each other, <br /> we will then make you an offer and <br /> your journey with us begins! </p>
                                        </div>
                                    </li>
                                </ul>
                                <p className="text-center kick-start-heading">
                                    <span>
                                        <a href="https://ab-inbev.skillate.com/" target="_blank">Kick-start your career today!</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainContent;