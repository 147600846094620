import React from "react";
import Slider from "react-slick";
import budwsier_manum from '../../assets/images/our_brands/budwsier_manum.png';
import becks from '../../assets/images/our_brands/becks.png';
import haywards from '../../assets/images/our_brands/haywards.png';
import kockout from '../../assets/images/our_brands/kockout.png';
class IndianBrands extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div className="beers-brands">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-xs-12 col-sm-1">
                            <h3 className="rotate-text">INDIAN&nbsp;BRANDS</h3>
                        </div>
                        <div className="col-md-11 col-xs-12 col-sm-11">
                            <div className="beers-list indian-banner">
                                <Slider {...settings}>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={budwsier_manum} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Budweiser Magnum</h3>
                                                                <p>A part of the Budweiser family in India, Budweiser Magnum was introduced in 2012. Budweiser Magnum is an American style lager with a full bodied, dark golden colour that is aged over Beechwood for refreshment and a crisp smoothness.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={becks} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Beck's Ice</h3>
                                                                <p>The European lager known for its German heritage since 1873, Beck’s Ice is a high-quality brew with 100% pure malt. It is the perfect smooth yet strong beer for an escape with friends.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={haywards} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Haywards 5000</h3>
                                                                <p>India’s original strong beer, Haywards 5000 is made from high quality malt to deliver an unmatched full bodied and flavorful taste. With its heritage dating back to 1984, Haywards 5000 It is a champion liquid for all the champions out there.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={kockout} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Knock Out</h3>
                                                                <p>One of the largest selling strong beers in the country, Knock Out is an iconic beer that is brewed with the finest ingredients in the state-of-the-art breweries to deliver a strong and flavorsome experience. The rich yet smooth drinking experience makes Knock Out a great companion for chilling with friends.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default IndianBrands;