import React from "react";
var ReactRotatingText = require('react-rotating-text');
class ViewAllJobs extends React.Component {
    render() {
        return(
            <div className="common-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="animate-scroll-new">DO YOU DREAM BIG?</div>
                            <div className="animate-scroll-new">ARE YOU  &nbsp; 
                                <ReactRotatingText items={['BOLD?', 'RESILIENT?', 'AMBITIOUS?', 'COLLABORATIVE?']} />
                            </div>
                            <div className="animate-scroll-new">WE NEED <span>YOU.</span>
                            </div>
                            <a href="https://ab-inbev.skillate.com/" target="_blank">VIEW ALL JOBS</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ViewAllJobs;