import React from "react";
class Heading extends React.Component {
    render() {
        return(
            <div className="common-heading-sub">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3><span>LEADERSHIP</span> TEAM</h3>
                            <p>Our leadership team has an ambitious vision and a strong track record of performance. This drives the innovative and people-focused business we are today.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Heading;