import React from "react";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom';
import { BrowserRouter, Routes, Route ,Navigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import App from './App';
import OurHistory from './pages/OurHistory';
import About from './pages/About';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Sustainability from './pages/Sustainability';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import OurValues from './pages/OurValues';
import DiversityInclusion from './pages/DiversityInclusion';
import GCCIndia from './pages/GCCIndia';
import BUIndia from './pages/BUIndia';
import GCCLeaders from './pages/GCCLeaders';
import BULeaders from './pages/BULeaders';
import LeapProgramme from './pages/LeapProgramme';
import BrewProgramme from './pages/BrewProgramme';
import GlobalManagementTraineeProgramme from './pages/GlobalManagementTraineeProgramme';
import SalesVisinoriesProgramme from './pages/SalesVisinoriesProgramme';
import SupplyManagementTraineeship from './pages/SupplyManagementTraineeship';
import OurBrands from './pages/OurBrands';
import FourNotFour from './pages/404';
import GCCBenefits from './pages/GCCBenefits';
import GCCBetterWorldInitiatives from './pages/GCCBetterWorldInitiatives';
import GCCLocations from './pages/GCCLocations';
import BUBenefits from './pages/BUBenefits';
import BUBetterWorldInitiatives from './pages/BUBetterWorldInitiatives';
import BULocations from './pages/BULocations';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import withAgeVerification from './components/withAgeVerification';
import NoRouteFoundPopup from './components/NoRouteFoundPopup';
import BUSocial from './pages/BUSocial';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Apply the withAgeVerification HOC to the components using separate variables
const AppWithAgeVerification = withAgeVerification( App);
const HomeWithAgeVerification = withAgeVerification(Home);
const OurHistoryWithAgeVerification = withAgeVerification(OurHistory);
const AboutWithAgeVerification = withAgeVerification(About);
const ContactWithAgeVerification = withAgeVerification(Contact);
const SustainabilityWithAgeVerification = withAgeVerification(Sustainability);
const OurValuesWithAgeVerification = withAgeVerification(OurValues);
// const DiversityInclusionWithAgeVerification = withAgeVerification(DiversityInclusion);
const GCCIndiaWithAgeVerification = withAgeVerification(GCCIndia);
const BUIndiaWithAgeVerification = withAgeVerification(BUIndia);
const GCCLeadersWithAgeVerification = withAgeVerification(GCCLeaders);
const BULeadersWithAgeVerification = withAgeVerification(BULeaders);
const LeapProgrammeWithAgeVerification = withAgeVerification(LeapProgramme);
const BrewProgrammeWithAgeVerification = withAgeVerification(BrewProgramme);
const GlobalManagementTraineeProgrammeWithAgeVerification = withAgeVerification(
  GlobalManagementTraineeProgramme
);
const SalesVisinoriesProgrammeWithAgeVerification = withAgeVerification(SalesVisinoriesProgramme);
const SupplyManagementTraineeshipWithAgeVerification = withAgeVerification(
  SupplyManagementTraineeship
);
const OurBrandsWithAgeVerification = withAgeVerification(OurBrands);
const FourNotFourWithAgeVerification = withAgeVerification(FourNotFour);
const GCCBenefitsWithAgeVerification = withAgeVerification(GCCBenefits);
const GCCBetterWorldInitiativesWithAgeVerification = withAgeVerification(
  GCCBetterWorldInitiatives
);
const GCCLocationsWithAgeVerification = withAgeVerification(GCCLocations);
const BUBenefitsWithAgeVerification = withAgeVerification(BUBenefits);
const BUBetterWorldInitiativesWithAgeVerification = withAgeVerification(
  BUBetterWorldInitiatives
);
const BULocationsWithAgeVerification = withAgeVerification(BULocations);
const PrivacyPolicyWithAgeVerification = withAgeVerification(PrivacyPolicy);
const TermsConditionsWithAgeVerification = withAgeVerification(TermsConditions);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="home" replace />} />
        <Route path="/age-verification" element={<AppWithAgeVerification />} exact />
        {/* Use the components with the HOC applied */}
        <Route path="/home" element={<HomeWithAgeVerification />} />
        <Route path="/our-history" element={<OurHistoryWithAgeVerification />} />
        <Route path="/about" element={<AboutWithAgeVerification />} />
        <Route path="/contact" element={<ContactWithAgeVerification />} />
        <Route path="/sustainability" element={<SustainabilityWithAgeVerification />} />
        <Route path="/our-values" element={<OurValuesWithAgeVerification />} />
        {/* <Route path="/diversity-inclusion" element={<DiversityInclusionWithAgeVerification />} /> */}
        <Route path="/gcc-india" element={<GCCIndiaWithAgeVerification />} />
        <Route path="/bu-india" element={<BUIndiaWithAgeVerification />} />
        <Route path="/gcc-leaders" element={<GCCLeadersWithAgeVerification />} />
        <Route path="/bu-leaders" element={<BULeadersWithAgeVerification />} />
        <Route path="/leap-programme" element={<LeapProgrammeWithAgeVerification />} />
        <Route path="/brew-programme" element={<BrewProgrammeWithAgeVerification />} />
        <Route path="/bu-social" element={<BUSocial />} />
        <Route
          path="/global-management-trainee-programme"
          element={<GlobalManagementTraineeProgrammeWithAgeVerification />}
        />
        <Route
          path="/sales-visinories-programme"
          element={<SalesVisinoriesProgrammeWithAgeVerification />}
        />
        <Route
          path="/supply-management-traineeship"
          element={<SupplyManagementTraineeshipWithAgeVerification />}
        />
        <Route path="/our-brands" element={<OurBrandsWithAgeVerification />} />
        <Route path="/404" element={<FourNotFourWithAgeVerification />} />
        <Route path="/gcc-benefits" element={<GCCBenefitsWithAgeVerification />} />
        <Route
          path="/gcc-better-world-initiatives"
          element={<GCCBetterWorldInitiativesWithAgeVerification />}
        />
        <Route path="/gcc-our-locations" element={<GCCLocationsWithAgeVerification />} />
        <Route path="/bu-benefits" element={<BUBenefitsWithAgeVerification />} />
        <Route
          path="/bu-better-world-initiatives"
          element={<BUBetterWorldInitiativesWithAgeVerification />}
        />
        <Route path="/bu-our-locations" element={<BULocationsWithAgeVerification />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyWithAgeVerification />} />
        <Route path="/terms-conditions" element={<TermsConditionsWithAgeVerification />} />
        {/* Fallback route for unmatched routes */}
        <Route path="*" element={<NoRouteFoundPopup />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>
);
