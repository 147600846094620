import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Bijoy from '../../../assets/images/GCC_Leaders/Bijoy.png';
class BijoyPinto extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Bijoy Pinto" src={Bijoy} />
                    </div>
                    <div className="details">
                        <h4>Bijoy Pinto</h4>
                        <p>Global Director, GCC Operations</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Bijoy Pinto" src={Bijoy} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Bijoy Pinto</h4>
                            <p className="sub-head">Global Director, GCC Operations</p>
                            <p className="content">Bijoy Rohan Pinto is the Global Director for GCC Operations at AB InBev GCC India.   

                                <br/><br/>In his role, Bijoy is responsible for building smart operations of the future, with talent of the future powered by Tech and Analytics.  Prior to this, he was the Value creation Director responsible for leading all Finance & Administration, Tax & Compliance, and Legal matters for GHQ India. He joined AB InBev India in 2017 as the ATR lead. Before this, he was the Technology & Analytics Director for 4 years driving various Digital Transformation initiatives within the Commercial team.  

                                <br/><br/>Bijoy has 20 years of experience & holds a bachelor’s degree in Financial & Management Accounting.  He also holds an advanced diploma in Management Accounting from The Chartered Institute of Management Accountants. Prior to joining AB InBev, he worked with HP & Accenture donning various roles in Finance, Outsourcing & Digital Transformation.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default BijoyPinto;