import React from 'react';
import Layout2 from '../components/Layout/Layout2';
import Banner from '../components/Sustainability/Banner';
import SustainabilityDetails from '../components/Sustainability/SustainabilityDetails';
import SustainabilityGoals from '../components/Sustainability/SustainabilityGoals';
import SustainabilityList from '../components/Sustainability/SustainabilityList';

class Sustainability extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <SustainabilityList />
            <SustainabilityGoals />
            <SustainabilityDetails />
        </Layout2>
      </>
    );
  }
}

export default Sustainability;