import React from 'react';
import Banner from '../components/BUSocial/Banner';
import Tabbs from '../components/BUSocial/Tabbs';
import Heading from '../components/BUSocial/Heading';
import Heading1 from '../components/BUSocial/Heading1';
import Cards1 from '../components/BUSocial/Cards1';
import Cards2 from '../components/BUSocial/Cards2';
import Heading2 from '../components/BUSocial/Heading2';
import Layout2 from '../components/Layout/Layout2';

class BUSocial extends React.Component {
  render() {
    return (
      <>
        <Layout2>
          <Banner />
          <Tabbs />
          <Heading />
          <Heading1></Heading1>
          <Cards1></Cards1>
          <Cards2></Cards2>
          <Heading2></Heading2>
        </Layout2>
      </>
    );
  }
}

export default BUSocial;