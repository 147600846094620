import React from "react";
import finance from '../../assets/images/BU_India/finance.jpeg';
class Finance extends React.Component {
    render() {
        return(
            <div className="bu-common-bu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="bu-common-main">
                                <div className="bu-left">
                                    <h4 className="d-block d-sm-none">Finance</h4>
                                    <div className="image">
                                        <img alt="Finance" src={finance} width="100%" />
                                    </div>
                                </div>
                                <div className="bu-right" data-aos="fade-up">
                                    <h4 className="d-none d-sm-block">Finance</h4>
                                    <p>Our Finance team enables growth by maintaining an unbiased view of resource allocation, managing business performance &amp; forward-looking planning while making sure our results and processes are fully compliant with rules and regulations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Finance;