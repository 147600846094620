import React from "react";
import AkhilSrivastava from "./LeadersList/AkhilSrivastava";
import AnasuyaRay from "./LeadersList/AnasuyaRay";
import AshishPalta from "./LeadersList/AshishPalta";
import AshwinKak from "./LeadersList/AshwinKak";
import KartikeyaSharma from "./LeadersList/KartikeyaSharma";
import MaheshKumarMittal from "./LeadersList/MaheshKumarMittal";
import TanviRohatgi from "./LeadersList/TanviRohatgi";
import VineetSharma from "./LeadersList/VineetSharma";

class LeadersList extends React.Component {
    render() {
        return(
            <div className="leaders-list">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="common-table">
                                <div className="common-table-left">
                                    <h2 >
                                        <span>BU&nbsp;India</span>
                                    </h2>
                                </div>
                                <div className="common-table-right leader-new-list">
                                    <div className="row">
                                        <KartikeyaSharma />
                                        <VineetSharma />
                                        <AshishPalta />
                                        <AnasuyaRay />
                                        <TanviRohatgi />
                                        <MaheshKumarMittal />
                                        <AkhilSrivastava />
                                        <AshwinKak />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default LeadersList;