import React, {useState} from "react";
import brian from '../../../assets/images/GCC_Leaders/brian.png';
import Modal from 'react-bootstrap/Modal';
class BrianDaSilva extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="profile-details">
                        <div className="image">
                            <img alt="Joanna Moraes" src={brian} />
                        </div>
                        <div className="details">
                            <h4>Brian da Silva</h4>
                            <p>Senior Director, Cloud & Data Center Operations </p>
                        </div>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={brian} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Brian da Silva</h4>
                            <p className="sub-head">Senior Director, Cloud & Data Center Operations </p>
                            <p className="content">Brian da Silva is the Senior Director for Cloud & Data Center operations based in AB InBev, GCC India. 

                                    Brian has over 25 years of experience in leading digital transformation initiatives with expertise spanning across various fields including IT services, cyber security, vendor management, project management & strategic transformation. His proficiency in these domains has been honed through his long tenure at AB InBev as a Director of TechOps & Security. He believes in being a catalyst for change, leveraging his extensive experience to guide our company towards successful digital transformation, enhanced cybersecurity, and optimized IT operations. 

                                    <br/><br/>With his exceptional leadership abilities and in-depth industry knowledge, Brian has consistently driven impactful outcomes, earning accolades for his accomplishments. </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default BrianDaSilva;