import React from "react";
class Heading extends React.Component {
    render() {
        return(
            <div className="common-heading-sub bu-india-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3>TOGETHER WE CAN <span>ACHIEVE ANYTHING</span></h3>
                            <p>We are committed to building great brands that stand the test of time, brewing the best beers using the finest natural ingredients, and offering the best brand experiences to our consumers, in a responsible way.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Heading;