import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import img5 from '../../assets/images/BUSocial/policy.png';

function Cards2(props) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '40px 0',
    }}>

      <Card className='Policy' style={{ width: '100%' }}>
        <style>
          {`
            .custom-button:hover {
              background-color: #E3B139 !important;
              border-color: #E3B139 !important;
            }

            .custom-button .button-text {
              color: #000;
              display: flex;
              align-items: center; /* Center the text and icon vertically */
            }

            .custom-button svg {
              margin-right: 5px;
              margin-top: -2px; /* Adjust the margin to move the icon up */
            }
          `}
        </style>
        <div className='yelloVertical'></div>
        <Card.Img variant="left" src={img5} />
        <Card.Body style={{ marginTop: '20px', marginBottom: '20px' }}>

          <Card.Title style={{ color: '#070707', fontWeight: '600', marginTop: '-13px', marginBottom: '10px' }}>
            Our Corporate Social Responsibility Policy
          </Card.Title>
          <Card.Text style={{ color: '#070707', fontSize: '17px' }}>
            Our CSR policy reflects our commitment to creating a positive impact on our communities. It encompasses our vision, objectives, governance, and responsible implementation of projects for a future with more cheers.
          </Card.Text>
          <Button
            variant="outline-dark"
            className="custom-button"
            href="/AB INBEV GCC India - CSR Policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="button-text">
              <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 0.5C10.25 0.36193 10.1381 0.25 10 0.25H3C1.48122 0.25 0.25 1.48122 0.25 3V17C0.25 18.5188 1.48122 19.75 3 19.75H13C14.5188 19.75 15.75 18.5188 15.75 17V7.14706C15.75 7.00899 15.6381 6.89706 15.5 6.89706H11C10.5858 6.89706 10.25 6.56127 10.25 6.14706V0.5ZM11 10.25C11.4142 10.25 11.75 10.5858 11.75 11C11.75 11.4142 11.4142 11.75 11 11.75H5C4.58579 11.75 4.25 11.4142 4.25 11C4.25 10.5858 4.58579 10.25 5 10.25H11ZM11 14.25C11.4142 14.25 11.75 14.5858 11.75 15C11.75 15.4142 11.4142 15.75 11 15.75H5C4.58579 15.75 4.25 15.4142 4.25 15C4.25 14.5858 4.58579 14.25 5 14.25H11Z" fill="black" />
              </svg>
              Read Our CSR Policy
            </span>
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Cards2;
