import React from "react";

class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner diversity-banner">
                <div className="common-banner-inner">
                    <h1><span className="red-text">WE’RE COMMITED TO</span><br/> DIVERSITY & INCLUSION</h1>
                </div>
            </section>
        );
    }
}
export default Banner;