import React from "react";
class MainContent extends React.Component {
    render() {
        return(
            <div>
                <div className="common-programme-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                <p>Our undergrad internships are full-time opportunities lasting two to six months. They’re a great way to get to know our business – and to get yourself noticed. Work in any function on a live project within a team. We’ll give you access to training resources, senior managers, and company events. Make an impact, and it could be the start of a promising career with us. To qualify, you’ll need to be an undergraduate in any discipline.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                                <h3>Ready to apply?</h3>
                                <p>Our selection process is designed to make sure you’re the right fit for our program, but more importantly, we are the right fit for you.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <p className="text-center aos-init" data-aos="fade-up" data-aos-duration="1000">Here’s what to expect:</p>
                                <ul className="selection-process-new aos-init" data-aos="fade-up" data-aos-duration="1000">
                                    <li>
                                        <div className="right-content">
                                            <span>1</span>
                                            <h4>Online <br /> Application </h4>
                                            <p>Apply online. All applications are reviewed, and only those that meet the criteria for any current open intern roles will be invited for an interview.</p>
                                        </div>
                                        <div className="line-left">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-address-card fa-w-18" data-fa-i2svg="" data-icon="address-card" data-prefix="fa" focusable="false" role="img" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="line-right left-content-new">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-play fa-w-14" data-fa-i2svg="" data-icon="play" data-prefix="fa" focusable="false" role="img" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                        <div className="left-content ">
                                            <h4>Video <br /> Interview </h4>
                                            <span>2</span>
                                            <p>If you’re a great fit, you’ll participate in a video interview, where we can meet you and ask you some questions to get to know you a bit better. You will also answer questions regarding your availability and preferences for the internship. We will then see if we have an opportunity that meets your interests.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="right-content">
                                            <span>3</span>
                                            <h4>Line Manager <br /> Interview </h4>
                                            <p>If you’ve ‘wowed us’ this far, you’ll be invited to your final group interview, with three to six other panel members, plus the most senior leaders at the organization.</p>
                                        </div>
                                        <div className="line-left left-content-new-1">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-users fa-w-20" data-fa-i2svg="" data-icon="users" data-prefix="fa" focusable="false" role="img" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="fourth-list">
                                        <div className="line-right">
                                            <div className="hexagon-syble">
                                                <div className="arrow-btm">&nbsp;</div>
                                                <svg aria-hidden="true" className="svg-inline--fa fa-envelope fa-w-16" data-fa-i2svg="" data-icon="envelope" data-prefix="fa" focusable="false" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" fill="currentColor"></path>
                                                </svg>
                                                <div className="arrow-top">&nbsp;</div>
                                            </div>
                                        </div>
                                        <div className="left-content">
                                            <h4>Offer</h4>
                                            <span>4</span>
                                            <p>If we’re the right fit for each other, we will then make you an offer, and your journey with us begins.</p>
                                        </div>
                                    </li>
                                </ul>
                                <p className="text-center kick-start-heading">
                                    <span>
                                        <a href="https://ab-inbev.skillate.com/" target="_blank">Kick-start your career today!</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainContent;