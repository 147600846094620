import React from 'react';

const ProgrammeContent = ({ title, description }) => {
  return (
    <div className="common-programme-content">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrammeContent;