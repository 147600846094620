import React from "react";
import CardContainer from "./CardContainer";

class Heading2 extends React.Component {
  render() {
    return (
      <section className="csr_committe">
        <div className="common-heading-sub" style={{ marginTop: '20px' }}>

          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <h3>
                  <span>CSR</span> COMMITTEE
                </h3>
                <CardContainer></CardContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Heading2;
