import React from "react";
class Rewarding extends React.Component {
    render() {
        return(
            <div className="bu-bhq-benefits">
                <div className="bu-india-head">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="rewarding">
                                    <div className="rewards-left">
                                        <span>REWARDING</span>
                                        <br /> YOUR DEDICATION
                                    </div>
                                    <div className="rewards-right">
                                        <p>Joining us means you’ll get a chance to make a global impact and work with some of the world’s most recognizable brands. We know that means hard work, dedication and commitment. So, we offer some great perks too.</p>
                                        <p className="second">
                                            <b>* Your personalized benefits package will depend on your position, location, and other eligibility requirements.</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Rewarding;