import React from 'react';
import Layout2 from '../components/Layout/Layout2';

class About extends React.Component {
  render() {
    return (
      <>
        <Layout2>
          <h1>
            About Page
          </h1>
        </Layout2>
      </>
    );
  }
}

export default About;