import React from "react";
import ghq_csr_icu from '../../assets/images/GCCBetterWorldInitiatives/ghq_csr_icu.jpeg';
import ghq_csr_covid from '../../assets/images/GCCBetterWorldInitiatives/ghq_csr_covid.jpeg';
import ghq_csr_fundraiser from '../../assets/images/GCCBetterWorldInitiatives/ghq_csr_fundraiser.jpeg';
class List extends React.Component {
    render() {
        return(
            <div className="our-entities our-graduate-programs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3 data-aos="fade-up" className="aos-init aos-animate">OUR <span>BETTER WORLD</span> INITIATIVES </h3>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                <div className="image">
                                    <img src={ghq_csr_icu} alt="Modular Medical Center and ICU" />
                                </div>
                                <div className="details">
                                    <h4>Modular Medical Center and ICU</h4>
                                    <p>We sponsored a 50-bed Modular Medical Center and ICU for St. Theresa’s Multi Speciality Hospital in Bengaluru. This medical center will be an extension to St. Theresa Hospital’s existing ICU unit. <br /> The modular structure has a durability of about 20 years and its multi-utility design allows it to be used for various medical purposes in the future. The negative pressure point cabins help eliminate the spread of contagious virus making it suitable for treating COVID-19 patients. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                                <div className="image">
                                    <img src={ghq_csr_covid} alt="COVID-19 Relief Initiatives" />
                                </div>
                                <div className="details">
                                    <h4>COVID-19 Relief Initiatives</h4>
                                    <p>We at AB InBev continue to support India in its fight against COVID-19. Our purpose as a company of bringing people together for a better world has never been more relevant than today. We are collaborating with state governments, medical institutions, and NGO partners to enhance health &amp; medical infrastructure and address the critical needs of affected communities. We have extending support by distributing 8000+ grocery and hygiene kits, contributing to 90,000+ meals in this time of need.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                                <div className="image">
                                    <img src={ghq_csr_fundraiser} alt="Employee Fundraiser for Communities affected by COVID-19" />
                                </div>
                                <div className="details">
                                    <h4>Employee Fundraiser for Communities affected by COVID-19</h4>
                                    <p>The second leg of the employee fundraiser initiative at AB InBev is enabling our communities to stand strong in these unprecedented times. Their contributions are geared towards addressing basic needs of affected communities. With contributions from employees, we have extended support by distributing 587 grocery. <br /> AB&nbsp;InBev will continue to support our communities and remain committed to playing our part in building a better world. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default List;