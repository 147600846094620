import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from './CustomModal';
import img1 from '../../assets/images/BUSocial/covid19.png';
import img3 from '../../assets/images/BUSocial/happiness box.png';
import img4 from '../../assets/images/BUSocial/school visit.png';

function Cards1(props) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <Card style={{ width: '100%', marginLeft: '10px', marginRight: '10px', padding: '10px', minHeight: '425px', borderRadius: '0', border: '1px solid #000', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div>
        <Card.Img variant="top" src={props.img} />
        <Card.Body>
          <Card.Title style={{ fontSize: '1rem', marginLeft: '-15px' }}>
            <span style={{ fontWeight: 'bold' }}>{props.title}</span>
          </Card.Title>
          <Card.Text style={{ fontSize: '-1px', marginLeft: '-15px' }}>
            {props.content.split(' ').slice(0, 25).join(' ')}
          </Card.Text>
        </Card.Body>
      </div>
      <div>
        {props.content.split(' ').length > 50 && (
          <Button variant="Light" className="custom-button" style={{ fontWeight: 'bold', fontSize: '12px', marginTop: '10px' }} onClick={handleShowModal}>
            Learn More
          </Button>
        )}
      </div>


      <Modal
        show={showModal}
        handleClose={handleCloseModal}
        title={props.title}
        content={props.content}
        img={props.img}
      />
    </Card>
  );
}

function CardContainer() {
  return (
    <div style={{
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
      maxWidth: '1100px',
      alignItems: 'center'
    }}>
      <Cards1 img={img1} title="Shakti Kits for Mothers" content="In collaboration with Akshaya Patra Foundation, we support 1000 vulnerable mothers with nutrition-rich grocery kits, aiming to improve their well-being during motherhood and impact their families positively.In partnership with Akshaya Patra Foundation, the initiative was undertaken to promote the welfare of women. It aims to support 1000 lactating and expecting mothers from economically disadvantaged communities by providing them with grocery kits and food supplies.Akshaya Patra Foundation plays a crucial role in meticulously assembling these kits to ensure that mothers receive the right nutrition for their health and well-being during this critical phase of motherhood. We strive to create a significant and lasting impact on the lives of these mothers and their children and families." />
      <Cards1 img={img3} title="RO Water Health Plants" content="We have partnered with Jaldhaara Foundation to enhance water quality in rural communities by installing 2 RO Water Health Plants that are benefiting over 20,000 people daily.In partnership with Jaldhaara Foundation, we are implementing a holistic approach to water stewardship with the goal of enhancing water quality in our rural communities.Along with Jaldhaara Foundation, we introduced 2 RO Water Health Plants as part of our global sustainability initiatives. This initiative addresses areas with limited infrastructure and scarce water resources, providing approximately 385,000 liters of safe drinking water daily to over 20,000 people. Our collective efforts aim to promote community well-being and work toward a brighter tomorrow. " />
      <Cards1 img={img4} title="Mid-Day Meals for Schoolchildren" content="In partnership with Akshaya Patra Foundation, we offer nutritious mid-day meals to 1700 children from 5 government schools to improve their learning outcomes. In partnership with Akshaya Patra Foundation, we provide nutritious mid-day meals to approximately 1700 schoolchildren in 5 government schools throughout the academic year. The initiative aims to support children from economically challenged communities. With the goal of improving the children’s learning outcomes, we ensure that these children receive wholesome meals. Together, we strive to ignite hope and joy in the lives of these children, forging a path toward a brighter future with more cheers. " />
    </div>
  );
}

export default CardContainer;
