import React from "react";
import Slider from "react-slick";
import budwiser_0_0 from '../../assets/images/our_brands/budwiser_0_0.png';
import heogaarden_0_0 from '../../assets/images/our_brands/heogaarden_0_0.png';
import heogaarden_rose from '../../assets/images/our_brands/heogaarden_rose.png';
class NonAlochoholic extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div className="beers-brands">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-xs-12 col-sm-1">
                            <h3 className="rotate-text">NON&nbsp;ALCHOHOLIC</h3>
                        </div>
                        <div className="col-md-11 col-xs-12 col-sm-11">
                            <div className="beers-list non-alchoholic-banner">
                                <Slider {...settings}>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={budwiser_0_0} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Budweiser 0.0</h3>
                                                                <p>Budweiser 0.0, The Non-Alcholic Beer that actually tastes like beer. Finally, a beer you can enjoy freely! Budweiser 0.0 is brewed in exactly the same way as Budweiser, with exactly the same ingredients. That’s because our commitment to brewing great beer never changes, even if we do decide to go alcohol-free.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={heogaarden_0_0} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Hoegaarden 0.0</h3>
                                                                <p>Hoegaarden is the mother of all Belgian-style wheat beers and is now available Alcohol-Free. This is a balanced beer, with coriander notes and a hint of orange peel which gives Hoegaarden its surprisingly smooth and highly refreshing taste.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={heogaarden_rose} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Hoegaarden Rosée</h3>
                                                                <p>Hoegaarden Rosée is also available in raspberry flavour - A unique concoction brewed by blending Hoegaarden 0.0 with natural raspberry pulp.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default NonAlochoholic;