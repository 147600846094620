import React from 'react';
import Banner from '../components/BUBetterWorldInitiatives/Banner';
import BUList from '../components/BUBetterWorldInitiatives/BUList';
import ViewMore from '../components/BUBetterWorldInitiatives/ViewMore';
import Layout2 from '../components/Layout/Layout2';
import ViewAllJobsDreamBig from '../components/BUIndia/ViewAllJobsDreamBig';

class BUBetterWorldInitiatives extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <BUList />
            <ViewMore />
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default BUBetterWorldInitiatives;