// App.js
import React from "react";
import AgeGate from "./components/AgeVerification/AgeGate";
import { BrowserRouter, Route } from "react-router-dom";

export default function App() {
  return (
    <>
      <AgeGate />
    </>
  );
}
