import React from "react";
import sandeepBalan from '../../assets/images/GCC_Leaders/sandeepBalan.jpeg';
import Runa from "./LeadersList/Runa";
import JoannaMoraes from "./LeadersList/JoannaMoraes";
// import SanjeevRavindranathan from "./LeadersList/SanjeevRavindranathan";
// import MarceloFonseca from "./LeadersList/MarceloFonseca";
import PrafullaSomani from "./LeadersList/PrafullaSomani";
import SajithKurup from "./LeadersList/SajithKurup";
// import AlexanderKomrakov from "./LeadersList/AlexanderKomrakov";
import VijoeMathew from "./LeadersList/VijoeMathew";
// import SumanaGM from "./LeadersList/SumanaGM";
import MadhurBhatia from "./LeadersList/MadhurBhatia";
// import SandeepBalan from "./LeadersList/SandeepBalan";
import BrianDaSilva from "./LeadersList/Brian_da_Silva";
import BijoyPinto from "./LeadersList/BijoyPinto";

class LeadersList extends React.Component {
    render() {
        return(
            <div className="leaders-list">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="common-table">
                                <div className="common-table-left">
                                    <h2 >
                                        <span>GCC&nbsp;India</span>
                                    </h2>
                                </div>
                                <div className="common-table-right leader-new-list">
                                    <div className="row">
                                        <SajithKurup />
                                        <BijoyPinto />
                                        <PrafullaSomani />
                                        <Runa />
                                        <BrianDaSilva />
                                        <VijoeMathew />
                                        <JoannaMoraes />
                                        <MadhurBhatia />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default LeadersList;