import React from "react";
import Layout2 from "../components/Layout/Layout2";
class FourNotFour extends React.Component {
    render() {
        return(
            <Layout2>
                <div>
                    <div className="animate-cup">
                        <div className="svg-404" id="animate">
                            <svg data-bx-workspace="master" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                                    <defs></defs>
                                    <g transform="matrix(1, 0, 0, 1, 129.141144, 121.849991)">
                                        <path d="M95.5,66.6v5.2h-4.4v-5.2H80.5v-2.4l10.3-14.5h4.7v13.4h2.7v3.5H95.5z M91.1,54.8c-0.5,0.8-1,1.6-1.5,2.3l-4.3,6h5.8V54.8z"></path>
                                        <path d="M117.9,60.8c0,6.9-2.3,11.5-8.6,11.5c-6.3,0-8.6-4.6-8.6-11.5c0-7,2.3-11.5,8.6-11.5C115.6,49.3,117.9,53.8,117.9,60.8z
                M113.3,60.8c0-5.6-1.2-8.2-3.9-8.2c-2.7,0-3.9,2.6-3.9,8.2c0,5.6,1.2,8.2,3.9,8.2C112,69,113.3,66.4,113.3,60.8z"></path>
                                        <path d="M135.5,66.6v5.2h-4.4v-5.2h-10.6v-2.4l10.3-14.5h4.7v13.4h2.7v3.5H135.5z M131.1,54.8c-0.5,0.8-1,1.6-1.5,2.3l-4.3,6h5.8
                V54.8z"></path>
                                        <text fill="#000000" className="page-not-found" x="86" y="160">PAGE</text>
                                        <text fill="#000000" className="page-not-found" x="91" y="190">NOT</text>
                                        <text fill="#000000" className="page-not-found" x="78" y="220">FOUND</text>
                                    </g>
                                    <path className="beer" d="M 190.341 137.65 C 186.641 184.75 205.641 205.05 208.641 245.65 C 211.641 286.25 188.941 349.65 191.641 359.65 C 194.341 369.65 288.941 370.65 286.941 357.35 C 284.941 344.05 269.241 285.05 272.241 242.05 C 275.241 199.05 289.641 196.05 286.941 137.75 L 190.341 137.65 L 190.341 137.65 Z"></path>
                                    <g transform="matrix(1, 0, 0, 1, 129.141144, 121.849991)">
                                        <path d="M 1.779 245.121 C 19.676 244.805 38.2 244.5 56.1 244.5 C 58.3 244.5 59.3 241.8 57.9 240.2 C 55 237.1 58.5 228.3 59.3 224.6 C 60.9 217.8 62.5 211.1 64 204.3 C 67.8 187.3 71.2 170.2 73.2 152.8 C 75.3 134.3 75.2 116.8 70.6 98.7 C 65.5 78.6 58 59.4 56 38.7 C 54.7 27.2 54.3 14.4 56 3.2 C 55.2 3.8 54.4 4.4 53.6 5 C 85.6 5 117.7 5 149.7 5 C 154.3 5 158.9 5 163.5 5 C 162.7 4.4 161.9 3.8 161.1 3.2 C 166 29.6 161.4 58.3 154.6 83.8 C 152.1 93.1 149.7 102.4 148.5 112 C 147.2 122.5 147.8 133.3 148.4 143.8 C 149.4 162.6 151.7 181.3 154.8 199.9 C 156.1 207.6 157.6 215.3 159.2 223 C 160 227 163.8 237.2 161 240.4 C 159.6 242 160.5 244.7 162.8 244.7 C 208.3 244.7 253.8 244.7 299.2 244.7 C 305.6 244.7 312.1 244.7 318.5 244.7 C 324.808 244.7 321.079 239.079 317.879 239.079 C 272.379 239.079 227.5 239.7 182.1 239.7 C 175.7 239.7 169.2 239.7 162.8 239.7 C 163.4 241.1 164 242.5 164.6 244 C 168.1 240.1 166.9 235 165.9 230.3 C 164.7 224.3 163.4 218.3 162.2 212.3 C 158.7 194.2 156 175.9 154.4 157.6 C 153.5 146.7 152.9 135.8 152.9 124.9 C 152.9 113.7 154.9 102.8 157.7 92 C 162.6 72.7 166.8 53.7 167.5 33.6 C 167.9 23.2 167.8 12.4 165.9 2.1 C 165.7 1 164.5 0.3 163.5 0.3 C 131.5 0.3 99.4 0.3 67.4 0.3 C 62.8 0.3 58.2 0.3 53.6 0.3 C 52.6 0.3 51.4 1.1 51.2 2.1 C 49.7 11.7 49.7 21.6 50.2 31.2 C 51.4 52.2 57.4 71.5 63.2 91.5 C 66.1 101.5 68.8 111.7 69.5 122.2 C 70.2 131.9 69.3 141.9 68.2 151.5 C 66.2 169.9 62.5 188 58.4 206 C 56.7 213.5 54.9 220.9 53.2 228.4 C 52.1 233.5 50.1 239.6 54.2 243.9 C 54.8 242.5 55.4 241.1 56 239.6 C 38.1 239.6 45.059 238.979 -82.225 240.843 C -85.326 240.888 -103.97 246.985 1.779 245.121 Z"></path>
                                    </g>
                                    <path d="M 198.141 370.85 C 198.841 371.45 199.641 371.75 200.541 371.95 C 201.441 372.15 202.241 372.35 203.141 372.45 C 204.941 372.75 206.641 372.95 208.441 373.05 C 211.941 373.35 215.541 373.45 219.041 373.65 C 222.641 373.75 226.141 373.85 229.741 373.85 C 233.341 373.85 236.841 373.95 240.441 373.85 C 244.041 373.85 247.541 373.85 251.141 373.75 C 254.741 373.75 258.241 373.55 261.741 373.35 C 265.241 373.15 268.841 372.95 272.341 372.55 C 275.841 372.25 279.441 371.75 282.841 370.85 C 279.641 372.65 276.141 373.65 272.641 374.55 C 269.141 375.35 265.541 376.05 261.941 376.45 C 258.341 376.95 254.741 377.25 251.141 377.45 C 247.541 377.65 243.941 377.75 240.341 377.85 C 236.741 377.85 233.141 377.75 229.541 377.55 C 225.941 377.35 222.341 377.15 218.741 376.65 C 215.141 376.25 211.541 375.65 208.041 374.95 C 206.241 374.55 204.541 374.15 202.741 373.55 C 201.841 373.25 201.041 372.95 200.241 372.55 C 199.541 372.05 198.641 371.65 198.141 370.85 Z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="not-found-maintainace">
                        <div className="container">
                            <div className="row">
                                    <div className="col-md-12 col-xs-12 col-sm-12">
                                        <div className="not-found">
                                            <p>We can’t find the page you’re looking for. <br /> You can either return to the previous page or visit our homepage. </p>
                                            <a href="/home">GO TO HOME PAGE</a>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout2>
            
        );
    }
}
export default FourNotFour;