import React, {useState} from "react";
import runa from '../../../assets/images/GCC_Leaders/runa.png';
import Modal from 'react-bootstrap/Modal';
class Runa extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Runa Dhawan" src={runa} />
                    </div>
                    <div className="details">
                        <h4>Runa Dhawan</h4>
                        <p>People Director, GCC India</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={runa} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Runa Dhawan</h4>
                            <p className="sub-head">People Director, GCC India</p>
                            <p className="content">Runa Dhawan is the People Director at AB InBev, GHQ India. She has a diverse corporate career spanning over 17 years, with roles in Learning and Development (L&D), Change Management, Talent Management, and Employee Experience. Currently serving as the People Director at AB InBev GCC, she oversees People Business Partnering, Employer Branding & Internal Communications, People Transformation & DEI (Diversity, Equity, and Inclusion) for a workforce of over 2000 employees. <br/><br/>With a background in English Literature from St. Xavier's College, Kolkata, Runa believes in the transformative power of words to inspire and drive change. She admires leaders who lead with integrity and courage, challenging their teams to continually improve while providing unwavering support. 

                            <br/><br/>Her role as People Director involves ensuring that the company's workforce is effectively supported, engaged, and developed, while also championing initiatives that promote diversity, equity, and inclusion within the organization. Runa's career trajectory reflects her commitment to creating positive and impactful workplaces through effective people management and leadership. </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default Runa;