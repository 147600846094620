import React from "react";
class Goals extends React.Component {
    render() {
        return(
            <div className="history-sustainability">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 text-center">
                            <h4>More than 600 years ago, we started revolutionizing beer.</h4>
                            <h2>Here's to the next 100!</h2>
                            <a href="/sustainability">SEE OUR 2025 GOALS</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Goals;