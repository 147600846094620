import React from "react";
class BrewingHistory extends React.Component {
    render() {
        return(
            <div className="common-heading-sub">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3><span>OUR SIX CENTURIES OF BREWING HISTORY</span></h3>
                            <p>We have a long history of devotion to brewing great beers that have stood the test of time. Our story began in Belgium, from where we went on to offer the most refreshing brews to the rest of the world.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BrewingHistory;