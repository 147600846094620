import React from 'react';
import Layout2 from '../components/Layout/Layout2';
import TermsContent from '../components/Privacy&TermsConditions/TermsContent';

const TermsConditions = () => {
    return (
      <>
        <Layout2>
            <TermsContent />
        </Layout2>
      </>
    );
}

export default TermsConditions;