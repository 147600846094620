import React from "react";
import Slider from "react-slick";
import Budweiser_Card_new from '../../assets/images/our_brands/Budweiser_Card_new.png';
import USA_Flag from '../../assets/images/our_brands/USA_Flag.svg';
import Corona_Card_new from '../../assets/images/our_brands/Corona_Card_new.png';
import corona_extra from '../../assets/images/our_brands/corona_extra.png';
import Hoegaarden_home from '../../assets/images/our_brands/Hoegaarden_home.png';
import heogorgen from '../../assets/images/our_brands/heogorgen.png';
class GlobalBrands extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div className="beers-brands">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-xs-12 col-sm-1">
                            <h3 className="rotate-text">GLOBAL&nbsp;BRANDS</h3>
                        </div>
                        <div className="col-md-11 col-xs-12 col-sm-11">
                            <div className="beers-list global-banner">
                                <Slider {...settings}>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={Budweiser_Card_new} />
                                                        </div>
                                                        <div className="flag">
                                                            <img src={USA_Flag} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Budweiser</h3>
                                                                <div>
                                                                    <a href="https://www.budweiser.com" target="blank" tabindex="0">www.budweiser.com</a>
                                                                </div>
                                                                <p>The yeast cells used to brew every bottle of our American-style lager are directly descended from the original culture used by founder Adolphus Busch. Launched in India in 2011, Budweiser is a full bodied, golden colour, American style lager – aged over Beechwood for refreshment and a crisp smoothness.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={Corona_Card_new} />
                                                        </div>
                                                        <div className="flag">
                                                            <img src={corona_extra} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Corona</h3>
                                                                <div>
                                                                    <a href="https://www.corona.com" target="blank" tabindex="0">www.corona.com</a>
                                                                </div>
                                                                <p>Corona Extra was born in Mexico as a clear golden lager with a naturally refreshing, crisp and light taste that is packaged in the iconic bottle and topped off with the quintessential and distinctive lime ritual.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="list-card">
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <div className="image">
                                                            <img src={Hoegaarden_home} />
                                                        </div>
                                                        <div className="flag">
                                                            <img src={heogorgen} />
                                                        </div>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <div className="flip-inner">
                                                            <div className="flip-inner-main">
                                                                <h3>Hoegaarden</h3>
                                                                <div>
                                                                    <a href="https://hoegaarden.com/" target="blank" tabindex="0">www.hoegaarden.com</a>
                                                                </div>
                                                                <p>Hoegaarden is the original Belgian wheat beer with a brewing legacy that goes back to over 600 years. It is brewed with natural ingredients like orange and coriander and is the most awarded wheat beer in the world. Its naturally fruity flavours and mild bitterness make it a perfect accompaniment with European food.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default GlobalBrands;