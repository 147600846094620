import React from "react";
import bu_india from '../../assets/images/home/bu_india.png';
import ghq_india from '../../assets/images/home/ghq_india.png';
class WhatWeDo extends React.Component {
    render() {
        return(
            <div className="what-we-do">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="what-we-do-heading">
                                <h3 className="common-heading">WHAT <span>WE DO</span></h3>
                                <p>In India, AB InBev is headquartered in Bangalore, and has a presence across the country with sales offices, breweries, a Global Capability Centre, and a Growth Analytics Center.</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <ul>
                                <li>
                                    <div className="port">
                                        <div className="image">
                                            <img src={ghq_india} />
                                        </div>
                                        <div className="portfolioDisc">
                                            <div className="details">
                                                <div className="details-main">
                                                    <h2>GCC INDIA</h2>
                                                    <p>AB InBev GCC India, located in Bangalore, supports AB InBev zones across the globe as the center for global capabilities of financial planning, analytics, and people services. With over 2000 employees, the GAC, Tech, and Operations capabilities centers drive global transformation and automation.</p>
                                                    <a href="/gcc-india">Know More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="port">
                                        <div className="image">
                                            <img src={bu_india} />
                                        </div>
                                        <div className="portfolioDisc">
                                            <div className="details">
                                                <div className="details-main">
                                                    <h2>BU INDIA</h2>
                                                    <p>AB InBev India, headquartered in Bangalore, Karnataka, is one of the leading beer and beverage companies that manufactures premium and super-premium brands with sales offices and breweries across the country.</p>
                                                    <a href="/bu-india">Know More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WhatWeDo;