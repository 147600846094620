import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import budweiser_card from '../../assets/images/home/Budweiser_Card.png';
import budweiser_magnum from '../../assets/images/home/budwsier_manum.png';
import corona_card from '../../assets/images/home/Corona_Card.png';
import fosters from '../../assets/images/home/fosters_hme.png';
import heywards from '../../assets/images/home/haywards.png';
import hoegaarden from '../../assets/images/home/Hoegaarden_home.png';
import knockout from '../../assets/images/home/kockout.png';
import becks from '../../assets/images/home/becks.png';
import usa from '../../assets/images/home/USA_Flag.svg';
import mexico from '../../assets/images/home/mexico_Flag.svg';
import belgium from '../../assets/images/home/belgium_Flag.svg';
import india from '../../assets/images/home/india_flag.png';
class OurBrands extends React.Component {
    render() {
        return(
            <div className="our-brands-home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="what-we-do-heading">
                                <h3 className="common-heading">OUR <span>BRANDS</span></h3>
                            </div>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12 carousel-brands">
                            <Carousel fade>
                                
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={budweiser_card}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={usa} alt={usa} /> 
                                                <span>Budweiser</span>
                                            </h3>
                                            <p>Launched in India in 2011, Budweiser is a full-bodied, golden, American-style lager, that is aged over Beechwood for a crisp smoothness and the ultimate refreshment. This is a historical beer with the yeast cells directly descending from the original culture used by founder Adolphus Busch.</p>
                                            <a className="visit-url" href="https://www.budweiser.com/" target="_blank">Visit www.budweiser.com</a>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={corona_card}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={mexico} alt={mexico} /> 
                                                <span>Corona</span>
                                            </h3>
                                            <p>Corona Extra was born in Mexico as a clear golden lager with a naturally refreshing, crisp and light taste that is packaged in the iconic bottle and topped off with the quintessential, distinctive lime ritual.</p>
                                            <a className="visit-url" href="https://www.corona.com" target="_blank">Visit www.corona.com</a>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={hoegaarden}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={belgium} alt={belgium} /> 
                                                <span>Hoegaarden</span>
                                            </h3>
                                            <p>Hoegaarden is the original Belgian wheat beer with a brewing legacy that goes back to over 600 years. It is brewed with natural ingredients like orange and coriander and is the most awarded wheat beer in the world.</p>
                                            <a className="visit-url" href="https://www.hoegaarden.com" target="_blank">Visit www.hoegaarden.com</a>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={budweiser_magnum}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={india} alt={india} /> 
                                                <span>Budweiser Magnum</span>
                                            </h3>
                                            <p>A part of the Budweiser family in India, Budweiser Magnum was introduced in 2012. Budweiser Magnum is an American style lager with a full-bodied, dark golden colour that is aged over Beechwood for for refreshing taste and a crisp smoothness.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={becks}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={india} alt={india} /> 
                                                <span>Beck's ice</span>
                                            </h3>
                                            <p>The European lager known for its German heritage since 1873, Beck’s Ice is a high-quality beer brewed with 100% pure malt. It is a perfectly smooth, strong beer for a cheerful evening with friends.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={heywards}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={india} alt={india} /> 
                                                <span>Haywards 5000</span>
                                            </h3>
                                            <p>India’s original strong beer, Haywards 5000, is made from high quality malt to deliver an unmatched full-bodied and flavorful taste. With its heritage dating back to 1984, Haywards 5000 is a champion liquid for all the champions out there.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={knockout}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={india} alt={india} /> 
                                                <span>Knock out</span>
                                            </h3>
                                            <p>One of the largest selling strong beers in the country, Knock Out is an iconic beer that is brewed using the finest ingredients in our state-of-the-art brewery to deliver a flavorsome experience.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={fosters}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                <img src={india} alt={india} /> 
                                                <span>Foster's</span>
                                            </h3>
                                            <p>Foster’s is an iconic Australian beer that delivers a crisp and refreshing taste. It is an easy-drinking lager inspired by the Australian beer culture for the ultimate refreshment.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="view-all-home-brands">
                                <a href="/our-brands">VIEW ALL</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default OurBrands;