import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import prafulla from '../../../assets/images/GCC_Leaders/prafulla.png';
class PrafullaSomani extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Prafulla Somani" src={prafulla} />
                    </div>
                    <div className="details">
                        <h4>Prafulla Somani</h4>
                        <p>Global Director, Tech Compliance </p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={prafulla} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Prafulla Somani</h4>
                            <p className="sub-head">Global Director, Tech Compliance</p>
                            <p className="content">Prafulla Somani is the Global Director of Tech Compliance at AB InBev GCC India. In this role, he is responsible for shaping risk management strategy and designing top-tier compliance frameworks. Prafulla has 22+ years of experience with a diverse background spanning finance, law, and compliance. He holds a Bachelor of Laws (LLB) degree and a Bachelor of Commerce (Hons.) degree. Additionally, he is a Chartered Accountant, having completed his qualification from The Institute of Chartered Accountants of India. 
                                <br/><br/>Prafulla joined AB InBev GCC in August 2017 with the primary responsibility of driving Tech Compliance Capabilities covering IT General Control, Software License Compliance, Data Privacy and Protection, Legal and Internal audit analytics, Brand Protection, and Cyber Security awareness and governance around global information security policy. Technology plays a critical role in the design, build, and operation of these capabilities while simultaneously reducing the cost of compliance. He has built robust compliance capabilities under COE, delivering legal and regulatory compliance, and achieving zero surprise internal and external audits at AB InBev.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default PrafullaSomani;