import React from "react";
import footer_icon_1 from '../../../assets/images/footer_icons/icon_1_footer.png';
import footer_icon_2 from '../../../assets/images/footer_icons/icon_2_footer.png';
import footer_icon_3 from '../../../assets/images/footer_icons/icon_3_footer.png';
import footer_icon_4 from '../../../assets/images/footer_icons/icon_4_footer.png';
import footer_icon_5 from '../../../assets/images/footer_icons/icon_5_footer.png';
import footer_icon_6 from '../../../assets/images/footer_icons/icon_6_footer.png';
import footer_icon_7 from '../../../assets/images/footer_icons/icon_7_footer.png';
import footer_icon_8 from '../../../assets/images/footer_icons/icon_8_footer.png';
import facebook from '../../../assets/images/social_icons/facebook-f.svg';
import linkedin from '../../../assets/images/social_icons/linkedin-in.svg';
import instagram from '../../../assets/images/social_icons/instagram.svg';
import twitter from '../../../assets/images/social_icons/twitter.svg';
class Footer2 extends React.Component {
    render() {
        return(
            <footer>
                <div className="drink-resp">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="drink-resp-inner">
                                    <h4>PLEASE DRINK RESPONSIBLY</h4>
                                    <p>Please do not share or forward this website or its contents to anyone underage.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-icons">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <ul>
                                    <li>
                                        <img src={footer_icon_1} alt="footer icons" />
                                    </li>
                                    <li>
                                        <img src={footer_icon_2} alt="footer icons" />
                                    </li>
                                    <li>
                                        <img src={footer_icon_3} alt="footer icons" />
                                    </li>
                                    <li>
                                        <img src={footer_icon_4} alt="footer icons" />
                                    </li>
                                    <li>
                                        <img src={footer_icon_5} alt="footer icons" />
                                    </li>
                                    <li>
                                        <img src={footer_icon_6} alt="footer icons" />
                                    </li>
                                    <li>
                                        <img src={footer_icon_7} alt="footer icons" />
                                    </li>
                                    <li>
                                        <img src={footer_icon_8} alt="footer icons" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-xs-12 col-sm-4">
                                <ul className="social-icons">
                                    <li>
                                        <a href="https://instagram.com/abinbevindia">
                                            <img src={instagram} alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://mobile.twitter.com/abinbevindia">
                                            <img src={twitter} alt="twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://in.linkedin.com/company/ab-inbev-india">
                                            <img src={linkedin} alt="linkedin" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/ABInBevIndia/">
                                            <img src={facebook} alt="facebook" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-4">
                                <p className="copy-right">© 2022 Anheuser-Bush InBev SA/NV All rights reserved</p>
                            </div>
                            <div className="col-md-4 col-xs-12 col-sm-4">
                                <ul className="terms-policy">
                                    <li>
                                        <a href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="/terms-conditions">Terms & Conditions</a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer2;