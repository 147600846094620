import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function CustomModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose} style={{ maxWidth: '100%' }}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img
            src={props.img}
            alt={props.title}
            style={{
              maxWidth: '55%',
              height: 'auto',
              float: 'left',
              marginRight: '20px',
              marginBottom: '10px'
            }}
          />
        </div>
        <div style={{ wordBreak: 'break-word' }}>{props.content}</div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
