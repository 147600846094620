import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner bu-locations">
                <div className="common-banner-inner">
                    <h1>OUR<span className="red-text"> LOCATIONS</span> </h1>
                </div>
            </section>
        );
    }
}
export default Banner;