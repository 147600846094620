import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import tanvi from '../../../assets/images/BU_Leaders/tanvi.png';
class TanviRohatgi extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Alexander Komrakov" src={tanvi} />
                    </div>
                    <div className="details">
                        <h4>Tanvi Rohatgi</h4>
                        <p>People Director – India & South East Asia, AB InBev</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={tanvi} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Tanvi Rohatgi</h4>
                            <p className="sub-head">People Director – India & South East Asia, AB InBev</p>
                            <p className="content">Tanvi Rohatgi heads the People (Human Resource) function for AB InBev in India & South East Asia. Tanvi is responsible for transforming the future of human capital at AB InBev. She joined as Associate Director, Global Risk Management, in 2019 and went on to work across various verticals including Strategy and Marketing within the company.<br/><br/>Driven by data and insights Tanvi is a strategist, team builder and a mentor who maximizes employee potential. She is focused on crafting exceptional employee experience, leadership development plans, driving culture ambassadors, and contributing to building a strong team of people at AB InBev in sync with changing future needs of work. With a career spanning over a decade, Tanvi has held leadership positions in national and global organizations like Paytm, Bain & Company, Amazon, Bill & Melinda Gates Foundation, to name a few.<br/><br/>Tanvi holds an MBA degree from INSEAD Business School and holds a Bachelor’s Degree in Business Studies from Shaheed Sukhdev College of Business Studies, Delhi University.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default TanviRohatgi;