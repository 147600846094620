import React from "react";
import di_5_5 from '../../assets/images/diversity_inclusion/di_5_5.png';
import di_2_2 from '../../assets/images/diversity_inclusion/di_2_2.png';
import di_1_1_1 from '../../assets/images/diversity_inclusion/di_1_1_1.png';
import di_4_4 from '../../assets/images/diversity_inclusion/di_4_4.png';


class SafeWorkPlace extends React.Component {
    render() {
        return(
            <div className="diversity-inclusion-equal">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h2>ENABLING A <span>SAFE WORKPLACE</span> THROUGH <span>DIVERSITY &amp; INCLUSION</span>
                            </h2>
                            <p>At AB InBev, we strive to make employees feel welcome and included. This comes from Workplace Safety initiatives. Few of the best practices are:</p>
                            <ul className="diversity-awards-top">
                                <li>
                                    <img alt="Gender-Neutral POSH policy" src={di_5_5} />
                                    <p>Gender-Neutral POSH Policy</p>
                                </li>
                                <li>
                                    <img alt="Gender-Neutral Domestic Violence policy" src={di_1_1_1} />
                                    <p>Gender-Neutral Domestic Violence Policy </p>
                                </li>
                                <li>
                                    <img alt="Foster and promote gender neutral language in the company" src={di_2_2} />
                                    <p>Foster and promote gender - neutral language in the company</p>
                                </li>
                                <li>
                                    <img alt="Anti-Discriminatory Policy" src={di_4_4} />
                                    <p>Anti-Discriminatory Policy</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SafeWorkPlace;