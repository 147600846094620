import React from 'react';
import Banner from '../components/GCCIndia/Banner';

import Layout2 from '../components/Layout/Layout2';
import PrivacyContent from '../components/Privacy&TermsConditions/PrivacyContent';

const PrivacyPolicy = () => {
    return (
      <>
        <Layout2>
            <PrivacyContent />
        </Layout2>
      </>
    );
}

export default PrivacyPolicy;