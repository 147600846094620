import React from 'react';

const PrivacyContentSection = (props) => {
    debugger
    return(
        <div className='privacy_content_section' id={props.id}>
            <div className='header'>
                {
                    props.data.header &&
                    <div className='title'>
                        {props.data.header}
                    </div>
                }
                {
                    props.data.header_desc &&
                    <div className='desc' dangerouslySetInnerHTML={{ __html: props.data.header_desc }}>
                        {/* {props.data.header_desc} */}
                    </div>
                }
            </div>
            {
                props.data.body &&
                <div className='body'>
                    <ul>
                        {
                            props.data.body.map((item) => {
                                return(
                                    <li>
                                        {
                                            item.li_header && 
                                                <span className='li_header'>
                                                    {item.li_header}
                                                </span>
                                        }
                                        <span className='li_body'>
                                            {item.li_body}
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
            {
                props.data.note &&
                <div className='notes'>
                    {props.data.note}
                </div>
            }
            {
                props.data.footer_desc &&
                <div className='footer' dangerouslySetInnerHTML={{ __html: props.data.footer_desc }}>
                </div>
            }

        </div>
    )
}

export default PrivacyContentSection;