import React from "react";
class Content extends React.Component {
    render() {
        return(
            <div className="our-brands-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <p>FROM LOCAL BREWS TO BELOVED CLASSICS TO HISTORIC RECIPES, WE BREW IT ALL. WITH WELL OVER 500 BRANDS AND COUNTLESS BEER VARIETIES, WE TAKE GREAT PRIDE IN EACH AND EVERY OUNCE.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Content;