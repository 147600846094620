import React from "react";
import creche from '../../assets/images/diversity_inclusion/creche.png';
import ParentalLeave from '../../assets/images/diversity_inclusion/ParentalLeave.png';
import carenine_program from '../../assets/images/diversity_inclusion/carenine_program.png';


class ParentalPolicies extends React.Component {
    render() {
        return(
            <div className="parental-policy-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="heading-bu">
                                <h3 className="more-about">Parental <span>Policies</span></h3>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details next-list">
                                <div className="image">
                                    <img alt="Extended Maternity Leave Policy" src={creche} width="100%" />
                                </div>
                                <div className="details">
                                    <h2>Extended Maternity Leave Policy</h2>
                                    <p>Mothers and primary caregivers have the option to extend their leave by an additional 3 months after completing 26 weeks of maternity leave.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details next-list">
                                <div className="image">
                                    <img alt="Parental Support Program" src={ParentalLeave} width="100%" />
                                </div>
                                <div className="details">
                                    <h2>Parental Support Program</h2>
                                    <p>A structured program that helps new parents (employees/spouses) with customized online interventions from experts. It includes gynecologists, nutritionists, life coaches, and fitness experts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details next-list">
                                <div className="image">
                                    <img alt="Lactating Rooms" src={carenine_program} width="100%" />
                                </div>
                                <div className="details">
                                    <h2>Lactating Rooms</h2>
                                    <p>We are proud to offer new mothers the facilities that they deserve. They will find support at our office with dedicated rooms for lactating mothers. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ParentalPolicies;