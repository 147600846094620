import React from 'react';
import Banner from '../components/BULocations/Banner';
import BULocationsList from '../components/BULocations/BULocationsList';
import ViewMore from '../components/BULocations/ViewMore';
import Layout2 from '../components/Layout/Layout2';
import ViewAllJobsDreamBig from '../components/GCCIndia/ViewAllJobsDreamBig';

class BULocations extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <BULocationsList />
            <ViewMore />
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default BULocations;