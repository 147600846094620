import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner supply-banner">
                <div className="common-banner-inner">
                    <h1>SUPPLY <span className="red-text">MANAGEMENT<br/>TRAINEESHIP</span> </h1>
                </div>
            </section>
        );
    }
}
export default Banner;