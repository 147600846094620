import React from 'react';
import Layout2 from '../components/Layout/Layout2';
import HomeBanner from '../components/Home/HomeBanner';
import WeAreBrewers from '../components/Home/WeAreBrewers';
import OurBrands from '../components/Home/OurBrands';
import WhatWeDo from '../components/Home/WhatWeDo';
import CampusProgrammes from '../components/Home/CampusProgrammes';
import ViewAllJobs from '../components/Common/ViewAllJobs';

class Home extends React.Component {
    render() {
        return (
            <>
                <Layout2>
                    <HomeBanner />
                    <WeAreBrewers />
                    <OurBrands />
                    <WhatWeDo />
                    <CampusProgrammes />
                    <ViewAllJobs />
                </Layout2>
            </>
        );
    }
}

export default Home;