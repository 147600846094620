import React from "react";

class Banner extends React.Component {
    render() {

        return (
            <section className="common-banner bu-bannersocial">
                <div className="common-banner-inner">
                    <h1>
                        <span className="red-text" style={{ fontSize: '20px', color: '#ffc107' }}>GCC &nbsp; India</span>
                    </h1>
                    <p style={{ fontSize: '55px', fontWeight: '600', marginBottom: '5px' }}>Corporate Social Responsibility</p>
                </div>
            </section>
        );
    }
}

export default Banner;
