import React from "react";
import initiatives from '../../assets/images/initiatives_new_mobile.png';
class Initiatives extends React.Component {
    render() {
        return(
            <div className="col-md-4 col-xs-12 col-sm-4">
                <div className="benefits-list">
                    <div className="image">
                        <img src={initiatives} width="100%" alt="OUR Better World Initiatives" />
                    </div>
                    <div className="details">
                        <h2>OUR Better World Initiatives</h2>
                        <p>Here's our India Story on our mission to bring people together for a better world.</p>
                        <a href="/bu-better-world-initiatives">
                            <span>VIEW OUR Initiatives</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Initiatives;