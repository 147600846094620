import React, {Component} from "react";
import FancyboxDiversity from "./fancybox.js";
import d_i_certified from '../../assets/images/diversity_inclusion/d_i_certified.jpeg';
import great_place_work_certificed from '../../assets/images/diversity_inclusion/great_place_work_certificed.png';
import gptw from '../../assets/images/diversity_inclusion/gptw.jpeg';
import best_place_work from '../../assets/images/diversity_inclusion/best_place_work.jpeg';


class DiversicyAwards extends React.Component {
    render() {
        return(
            <div className="all-diverse-slates">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3>CELEBRATING ALL DIVERSE SLATES</h3>
                            <FancyboxDiversity options={{ dragToClose: false }}>
                                <ul className="main-img-slider diversity-awards">
                                    <li>
                                        <a className="image-fancy" data-fancybox="gallery" href={d_i_certified}>
                                            <img src={d_i_certified} />
                                        </a>
                                        <a className="title" href=" https://www.linkedin.com/posts/ab-inbev-india_abinbevindia-abinbevgcc-greatplacetowork-activity-6826157506859991040-d_Tu" target="_blank">Great Place to Work Certified<br />AB InBev GCC </a>
                                    </li>
                                    <li>
                                        <a className="image-fancy" data-fancybox="gallery" href={great_place_work_certificed}>
                                            <img src={great_place_work_certificed} />
                                        </a>
                                        <a className="title" href="https://www.linkedin.com/posts/ab-inbev-india_abinbevindia-abinbevgcc-greatplacetowork-activity-6848961357375700992-bzRW" target="_blank">Best Workplaces for Women<br />AB InBev GCC</a>
                                    </li>
                                    <li>
                                        <a className="image-fancy" data-fancybox="gallery" href={gptw}>
                                            <img src={gptw} />
                                        </a>
                                        <a className="title" href="https://www.linkedin.com/posts/ab-inbev-india_abinbev-greatplacetowork2020-bestbeerjob-activity-6788050650933547008-GcKY" target="_blank">Great Place to Work Certified<br />AB InBev BU</a>
                                    </li>
                                    <li>
                                        <a className="image-fancy" data-fancybox="gallery" href={best_place_work}>
                                            <img src={best_place_work} />
                                        </a>
                                        <a className="title" href="https://www.linkedin.com/posts/ab-inbev-india_togetherforbetter-inclusionanddiversity-inclusionmatters-activity-6810437234618101760-7OTx" target="_blank">Best Workplaces for Women<br />AB InBev BU</a>
                                    </li>
                                </ul>
                                
                            </FancyboxDiversity>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default DiversicyAwards;