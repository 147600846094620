import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
// import img6 from '../../assets/images/BUSocial/sanjeev.png';
import runa from '../../assets/images/BUSocial/Runa.png';
import img7 from '../../assets/images/BUSocial/Bijoy.png';

function Cards3(props) {
  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(true);
  };

  const handleShowLessClick = () => {
    setShowMore(false);
  };

  return (
    <div>
      <Card className='Social'>
        <Card.Img
          variant="left"
          src={props.img}
          style={{
            maxWidth: '205px',
            maxHeight: '209px',
            paddingTop: '6px',
            paddingBottom: '2px',
            paddingLeft: '20px',
          }}
        />
        <Card.Body style={{ paddingTop: '24px', paddingBottom: '24px' }}>

          <Card.Title style={{ fontWeight: 600, marginBottom: '10px', fontSize: '24px' }}>
            {props.title.split('|').map((part, index) =>
              index === 1 ? (
                <span key={index} style={{ color: '#000', fontSize: '24px' }}>
                  |{part}
                </span>
              ) : (
                part
              )
            )}
          </Card.Title>
          <Card.Text style={{ fontSize: '17px' }}>
            {showMore ? (
              props.content
            ) : (
              <>
                {props.content.length > 300
                  ? props.content.slice(0, 200) + '...'
                  : props.content}
                {props.content.length > 300 && (
                  <span
                    style={{
                      color: '#000',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                    onClick={handleReadMoreClick}
                  >
                    {' '}
                    Read more
                  </span>
                )}
              </>
            )}
          </Card.Text>
          {showMore && (
            <span
              style={{
                color: '#000',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              onClick={handleShowLessClick}
            >
              {' '}
              Show less
            </span>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

function CardContainer(props) {
  return (
    <div
      style={{
        backgroundColor: '#yourDesiredColor',
        padding: '9px 20px',
        margin: '20px 0',
      }}
    >
      <Cards3
        img={img7}
        title="Bijoy Rohan Pinto | Global Director, GCC Operations"
        content="Bijoy Rohan Pinto is the Global Director for GCC Operations at AB InBev GCC India.   
In his role, Bijoy is responsible for building smart operations of the future, with talent of the future powered by Tech and Analytics.  Prior to this, he was the Value creation Director responsible for leading all Finance & Administration, Tax & Compliance, and Legal matters for GHQ India. He joined AB InBev India in 2017 as the ATR lead. Before this, he was the Technology & Analytics Director for 4 years driving various Digital Transformation initiatives within the Commercial team.  
Bijoy has 20 years of experience & holds a bachelor’s degree in Financial & Management Accounting.  He also holds an advanced diploma in Management Accounting from The Chartered Institute of Management Accountants. Prior to joining AB InBev, he worked with HP & Accenture donning various roles in Finance, Outsourcing & Digital Transformation."
      />
      <Cards3
        img={runa}
        title="Runa Dhawan | People Director, GCC India"
        content="Runa Dhawan is the People Director at AB InBev, GHQ India. She has a diverse corporate career spanning over 17 years, with roles in Learning and Development (L&D), Change Management, Talent Management, and Employee Experience. Currently serving as the People Director at AB InBev GCC, she oversees People Business Partnering, Employer Branding & Internal Communications, People Transformation & DEI (Diversity, Equity, and Inclusion) for a workforce of over 2000 employees.With a background in English Literature from St. Xavier's College, Kolkata, Runa believes in the transformative power of words to inspire and drive change. She admires leaders who lead with integrity and courage, challenging their teams to continually improve while providing unwavering support. 
Her role as People Director involves ensuring that the company's workforce is effectively supported, engaged, and developed, while also championing initiatives that promote diversity, equity, and inclusion within the organization. Runa's career trajectory reflects her commitment to creating positive and impactful workplaces through effective people management and leadership. "
      />
      
    </div>
  );
}

export default CardContainer;
