import React from "react";
import legal_corporate_affairs from '../../assets/images/BU_India/legal_corporate_affairs.jpeg';
class CorporateAffairs extends React.Component {
    render() {
        return(
            <div className="bu-common-bu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="bu-common-main">
                                <div className="bu-right">
                                    <h4>Corporate Affairs</h4>
                                    <p className="d-none d-sm-block">Our Legal &amp; Corporate affairs team protects our company and enables growth through mergers and acquisitions, innovative patents, compliance, and more. They respond quickly to situations around the world, create and share content about AB InBev, our people, our heritage, and our sustainability work.</p>
                                </div>
                                <div className="bu-left">
                                    <div className="image">
                                        <img alt="Corporate Affairs" src={legal_corporate_affairs} width="100%" />
                                    </div>
                                    <p className="d-block d-sm-none">Our Legal &amp; Corporate affairs team protects our company and enables growth through mergers and acquisitions, innovative patents, compliance, and more. They respond quickly to situations around the world, create and share content about AB InBev, our people, our heritage, and our sustainability work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CorporateAffairs;