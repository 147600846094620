import React, {useState} from "react";
import operations_gcc from '../../assets/images/GCC_India/operations_gcc.jpeg';
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
class Analytics extends React.Component {
    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    openModal1 = () => this.setState({ isOpen1: true });
    closeModal1 = () => this.setState({ isOpen1: false });
    openModal2 = () => this.setState({ isOpen2: true });
    closeModal2 = () => this.setState({ isOpen2: false });
    openModal3 = () => this.setState({ isOpen3: true });
    closeModal3 = () => this.setState({ isOpen3: false });
    openModal4 = () => this.setState({ isOpen4: true });
    closeModal4 = () => this.setState({ isOpen4: false });
    openModal5 = () => this.setState({ isOpen5: true });
    closeModal5 = () => this.setState({ isOpen5: false });
    openModal6 = () => this.setState({ isOpen6: true });
    closeModal6 = () => this.setState({ isOpen6: false });
    openModal7 = () => this.setState({ isOpen7: true });
    closeModal7 = () => this.setState({ isOpen7: false });
    openModal8 = () => this.setState({ isOpen8: true });
    closeModal8 = () => this.setState({ isOpen8: false });
    openModal9 = () => this.setState({ isOpen9: true });
    closeModal9 = () => this.setState({ isOpen9: false });
    render() {
        // const [smShow, setSmShow] = useState(false);
        // const [lgShow, setLgShow] = useState(false);
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            
            <div className="teams-main-page bhq-india analytics-main">
                <div className="teams-main-page-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-xs-12 col-sm-2">
                                <h2 className="head-rotate">ANALYTICS</h2>
                            </div>
                            <div className="col-md-10 col-xs-12 col-sm-10 carousel-teams">
                                <div className="details">
                                    <div className="details-main">
                                        <p>The Global Analytics Center focuses on high-impact advanced analytics projects. This team functions as a centralized Analytics entity for the organization with a high focus on driving education around analytics.</p>
                                    </div>
                                    <div className="image">
                                        <img alt="GCC India Operations Team" src={operations_gcc} />
                                    </div>
                                </div>
                                <div className="team-name-main">ANALYTICS Teams</div>
                                <Slider {...settings}>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Operations Analytics</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Operations Analytics</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Operations Analytics team drives all analytical solutions required for AB InBev’s Operations towers. This team embeds analytics to transform their operational capabilities.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal1}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Forecasting</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen1} onHide={this.closeModal1} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Forecasting</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Forecasting team studies macro-economic drivers and financial reports to predict industry-level volumes in each country for one to three years in the future. This team also forecasts more granular profit and loss line items. Both forecasts help in commercial budget allocations and treasury to manage AB InBev’s leverage &amp; profitability respectively.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal2}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Sales</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen2} onHide={this.closeModal2} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Sales</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Sales team provides analytical solutions to several problems that range from helping our customers with the right category to SKU (stock-keeping unit) decisions by the channel that maximizes revenue and category growth share of beverage, measuring and recommending ways to improve our NPS (net promoter score), right up to helping front-line reps/B2B (business to business) with intelligence in competitive surroundings and prioritizing actions for them.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal3}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>Supply and Logistics</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen3} onHide={this.closeModal3} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Supply and Logistics</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Supply and Logistics team focuses on the brewery side and the logistics side of AB InBev. This team improves the overall efficiency of AB InBev's brewery manufacturing process and logistics through better planning, better routes, and distribution using intelligence provided by data and analytics. This team looks into the entire product lifecycle starting from ideation – development – deployment – to change management.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div className="list-teams">
                                            <div className="details-team-dots" onClick={this.openModal4}>
                                                <div className="box-teams">&nbsp;</div>
                                                <div className="team-name"><span>People Analytics</span></div>
                                                <div className="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen4} onHide={this.closeModal4} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">People Analytics</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our People Analytics team oversees several key aspects across AB InBev's people cycle including retention, rewards program, and performance analytics. This team is also branching out in helping other functions such as learning and development – all using the power of data and analytics.</Modal.Body>
                                        </Modal>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Analytics;