import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import video_bg from "../../assets/videos/Age-Gate.mp4";
import logo_age from "../../assets/images/Logo-Yellow-White.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";

export default function AgeGate() {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [yearInput, setYearInput] = useState("");
  const [year, setYear] = useState("");


  function addLeadingZero(value) {
    if (value < 10){
    return value.toString().padStart(2, '0');
    }else {
      // console.log('value',value)
      return value.replace(/^0+/, '');
    }
  }
  
  const handleDayChange = (event) => {
    // console.log('input',event.target.value)
    const inputDay = event.target.value;
    const sanitizedInput = inputDay.replace(/\D/g, '');
    const truncatedInput = sanitizedInput;
    // console.log('truncatedInput',truncatedInput)
    if (truncatedInput === "" || truncatedInput < 1 || truncatedInput > 31) {
      // invalid day
      setDay("");
    } else {
      
      setDay(addLeadingZero(truncatedInput));
    }
  };

  const handleMonthChange = (event) => {
    const inputMonth = event.target.value;
    const sanitizedInput = inputMonth.replace(/\D/g, '');
    const truncatedInput = sanitizedInput;
    if (truncatedInput === "" || truncatedInput < 1 || truncatedInput > 12) {
      // invalid month
      setMonth("");
    } else {
      setMonth(addLeadingZero(truncatedInput));
    }
  };

  const handleYearChange = (event) => {
    const inputYear = event.target.value;
    const sanitizedInput = inputYear.replace(/\D/g, '');
    const truncatedInput = sanitizedInput.slice(0, 4);
    setYearInput(truncatedInput); // Set the yearInput separately, don't update the state yet
  };

// const handleYearChange = (event) => {
//         const inputYear = event.target.value;
//         const sanitizedInput = inputYear.replace(/\D/g, '');
//         const truncatedInput = sanitizedInput.slice(0, 4);
//         console.log(truncatedInput);
//         console.log(inputYear);
//         const currentYear = new Date().getFullYear();
//         if (truncatedInput === "" || truncatedInput < 1900 || truncatedInput > currentYear) {
//             // invalid year
//             setYear("");
//         } else {
//             setYear(truncatedInput);    
//         }
//       }

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const currentDate = new Date();
    const dobDate = new Date(`${yearInput}-${month}-${day}`);
    const ageDiffMs = currentDate - dobDate;
    const ageDate = new Date(ageDiffMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age < 18 || month === "" || yearInput === "" || day === "") {
      alert("Sorry, you must be 18 years or older to access this website.");
    } else {
      Cookies.set("ageVerified", "true");
      setYear(yearInput);
      setDay("");
      setMonth("");
      setYearInput("");
      navigate("/home"); // Redirect to the home page
    }
  };

  return (
    <Layout>
      <div className="age-verification">
        <video autoPlay muted loop id="myVideo">
          <source src={video_bg} type="video/mp4" />
        </video>
        <div className="age-gate-content">
          <div className="age-top-content">
            <div className="logo">
              <img src={logo_age} width="200" alt="logo" />
              <p>
                As part of our commitment to responsible drinking, <br />
                please confirm you are of legal drinking age.
              </p>
            </div>
            <div className="age-verification-form">
              <Form className="row" onSubmit={handleSubmit}>
                <Form.Group className="col-md-3" controlId="formBasicEmail">
                  <Form.Control
                    type="number"
                    min="0"
                    max="31"
                    placeholder="DD"
                    value={day}
                    onChange={handleDayChange}
                  />
                </Form.Group>

                <Form.Group className="col-md-3" controlId="formBasicPassword">
                  <Form.Control
                    type="number"
                    min="0"
                    max="12"
                    placeholder="MM"
                    value={month}
                    onChange={handleMonthChange}
                  />
                </Form.Group>

                <Form.Group className="col-md-3" controlId="formBasicPassword">
                  <Form.Control
                    type="number"
                    min="1900"
                    max="2023"
                    placeholder="YYYY"
                    value={yearInput}
                    onChange={handleYearChange}
                  />
                </Form.Group>

                <div className="col-md-3">
                  <Button variant="primary" type="submit">
                    Verify
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="age-gate-footer d-block d-sm-none">
            <div className="col-md-12">
              <p>
                By submitting this form, you agree to be bound by the Terms of
                Service and Privacy Policy.
              </p>
              <p>ENJOY RESPONSIBLY. DO NOT SHARE THIS CONTENT WITH MINORS.</p>
              <p>
                <a href="/privacy-policy">PRIVACY POLICY</a> |{" "}
                <a href="/terms-conditions">TERMS &amp; CONDITIONS</a>
              </p>
              <p className="copy-right">
                © 2022 Anheuser-Bush InBev SA/NV All rights reserved
              </p>
            </div>
          </div>
        </div>
        <div className="age-gate-footer d-none d-sm-block">
          <div className="col-md-12">
            <p>
              By submitting this form, you agree to be bound by the Terms of
              Service and Privacy Policy.
            </p>
            <p>ENJOY RESPONSIBLY. DO NOT SHARE THIS CONTENT WITH MINORS.</p>
            <p>
              <a href="/privacy-policy">PRIVACY POLICY</a> |{" "}
              <a href="/terms-conditions">TERMS &amp; CONDITIONS</a>
            </p>
            <p className="copy-right">
              © 2022 Anheuser-Bush InBev SA/NV All rights reserved
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
