import React from 'react';
import PrivacyContentSection from './PrivacyContentSection'

const PrivacyContent = () => {
    const what_we_collect = {
        header: 'What Information We Collect',
        header_desc:'',
        body: [
            {
                'li_header':'We collect contact information:',
                'li_body':'For example, if you sign up for a mailing list, create an account, or enter a promotion, you may be asked to provide your name, address, email, online identifier, username, cell phone number, and/or date of birth.'
            },
            {
                'li_header':'We collect information you submit or post:',
                'li_body':'We collect information that you post in a public space on the Platform, submit requests or inquiries to us (e.g., to participate in our loyalty or rewards programs, a service request or product request), or information related to your social media activity (e.g., posts, photos, followers, social media handle). We also collect information when you contact us or when you apply for a job.'
            },
            {
                'li_header':'We collect demographic information:',
                'li_body':'We may collect information as may be allowed under Indian laws. We may also collect your PIN code.'
            },
            {
                'li_header':'We collect usage information:',
                'li_body':'We may collect information about the browser you are using. We might look at what site you came from or what site you visit when you leave the Platform. If you are using a mobile app, we may collect location information, including your precise location, if you have enabled location services in your device settings. We may also collect device information, including IP address and device identifiers.'
            },
            {
                'li_header':'We collect certain information on interests and preferences:',
                'li_body':'We may collect information on your interests (e.g., music or sports), preferences, and purchases.'
            },
            {
                'li_header':'Note about Credit Card Information:',
                'li_body':'If you are making a purchase on the Platform, you may be asked to provide credit card information. In such event, it is our policy to redirect you to a payment processing portal hosted by a third party payment processor. Please read the privacy policy on our payment processor’s website regarding the use, storage and protection of your credit card information before submitting any credit card information'
            },
        ],
        note: '',
        footer_desc:''
    }

    const how_we_collect = {
        header: 'How We Collect Information',
        header_desc: 'We collect information from different sources and across devices. For example:',
        body: [
            {
                'li_header':'We collect information directly from you:',
                'li_body':'If you choose not to provide the information that we ask for, it is possible that you may not be able to participate in an activity or experience the full functions of the Platform.'
            },
            {
                'li_header':'We collect information from you passively:',
                'li_body':'For instance, we use certain standard web measurement and tracking technologies such as "cookies," web server logs, web beacons (also known as "action tags" or "one pixel .gif files"), clear GIFs or other statistics programs. We do this on our Platform (including mobile apps) and in emails that we send to you. We collect information about users over time when you use this Platform (including mobile apps). We have third parties collect information this way, as well. To learn more about these tools and how you can control them, click here. Your browser may have an option that allows you to accept cookies, reject cookies, or receive notification when a cookie is sent, but you should note that the use of such restrictive browser settings may limit your use of the Platform.'
            },
            {
                'li_header':'We get information about you from third parties:',
                'li_body':'For example, our business partners may give us information about you. Social media platforms may also give us information about you.'
            },
            {
                'li_header':'We and our business partners may combine information:',
                'li_body':'For example, we and our business partners combine information that we have collected offline with information we collect online. We and our business partners may combine information collected across different devices over time.'
            },
        ],
        note: '',
        footer_desc:''
    }
    
    const how_we_use = {
        header: 'How We Use Information',
        header_desc: 'We use your information for the following purposes:',
        body: [
            {
                'li_header':'',
                'li_body':'to establish that you are of legal age to use our products and services'
            },
            {
                'li_header':'',
                'li_body':'to improve our products and services and to customize your experience with us'
            },
            {
                'li_header':'',
                'li_body':'to provide the products and services you request, including our loyalty and rewards programs'
            },
            {
                'li_header':'',
                'li_body':'to communicate with you about our relationship and respond to inquiries we receive from you or in connection with a transaction you initiate'
            },
            {
                'li_header':'',
                'li_body':'to analyze, manage and improve the Platform and our products and services, including by analyzing preferences, interests, and purchases'
            },
            {
                'li_header':'',
                'li_body':'to use it within ABI'
            },
            {
                'li_header':'',
                'li_body':'to notify you of special offers and other services that we believe may be of interest to you or to send you current mailings. To learn about your choices with regard to these communications, read the Choices Choices section below'
            },
            {
                'li_header':'',
                'li_body':'to provide you with advertisements about goods and services that may be of interest to you'
            },
            {
                'li_header':'',
                'li_body':'to notify you that you have been selected as a winner of a contest or sweepstakes'
            },
            {
                'li_header':'',
                'li_body':'to help us protect the Platform and our customers'
            },
            {
                'li_header':'',
                'li_body':'to help us diagnose problems with our server or other technical issues relating to the performance of the Platform'
            },
            {
                'li_header':'',
                'li_body':'for any other purpose as otherwise permitted by law or as stated at the time you provide your information'
            },
        ],
        note: 'Note about third-party uses: If you voluntarily disclose personal information (e.g., name, email address, personal photographs) in a community forum, such information may be collected and used by others and may result in unsolicited messages from other parties. We have no control over this type use of your personal information and are not responsible for that use.',
        footer_desc: 'We generally keep your personal information related to marketing activities for a period of 2 years as from the last engagement or interaction with you, and we will securely delete such information in accordance with applicable law upon request. For personal information that we collect and process for other purposes, we will typically retain such personal information for as long as it is necessary to fulfil the purposes outlined above.'
    }

    const how_we_share = {
        header: 'How We Share Your Information',
        header_desc: 'We may share the information we collect from and about you with third parties:',
        body: [
            {
                'li_header':'',
                'li_body':'We share information with third parties who provide services on our behalf, such as vendors who send emails or serve advertisements for us, or who operate the Platform or run promotions on our behalf'
            },
            {
                'li_header':'',
                'li_body':'We share information to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, customers or other users of the Platform, or anyone else who could be harmed by such activities'
            },
            {
                'li_header':'',
                'li_body':'We share information to enforce the Terms and Conditions, take precautions against liability, investigate and defend against any third-party claims or allegations, and/or protect the security or integrity of the Platform'
            },
            {
                'li_header':'',
                'li_body':'We share information to respond to judicial process and provide information to law enforcement agencies in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law, or in the good-faith belief that such action is necessary to comply with applicable laws.'
            },
            {
                'li_header':'',
                'li_body':'We share information with our business partners, such as companies that co-sponsor a promotion.'
            },
            {
                'li_header':'',
                'li_body':'We may share information with any successor to all or part of our business or any entity that we acquire (for instance if part of our business is sold, we may give our customer list as part of that transaction)'
            },
            {
                'li_header':'',
                'li_body':'We may share your information with creditors, if required, in the event of our bankruptcy, insolvency, or reorganization'
            },
            {
                'li_header':'',
                'li_body':'We may share information for other reasons we disclose to you.'
            },
            {
                'li_header':'',
                'li_body':'For service efficiency purposes, some of these recipients are located in territories outside the country where your personal information has been collected, including countries which do not offer a level of data protection comparable to that of the country where your information has been collected. In such cases, we inform you that, where required under applicable data protection law, we will transfer your information with adequate safeguards and always keeping your information safe'
            },
        ],
        note: '',
        footer_desc: ''
    }

    const choice_rights = {
        header: 'You Have Certain Choices and Rights About How We Use Your Information',
        header_desc: 'Where applicable and under the conditions set forth under applicable data protection laws, you may have the following rights concerning the processing of your personal information:',
        body: [
            {
                'li_header':'Access:',
                'li_body':'Subject to certain exceptions, you have the right to request information on the information we store concerning you as well as the purpose of storage and a copy of the personal information we are processing about you, which we will provide to you in electronic form. At our discretion we may require you to prove your identity before providing the requested information. If you require multiple copies of your personal information, we may charge a reasonable administration fee.'
            },
            {
                'li_header':'Rectification:',
                'li_body':'You have the right to require that any incomplete or inaccurate personal information that we process about you is amended.'
            },
            {
                'li_header':'Erasure:',
                'li_body':'You have the right to request that we delete personal information that we process about you, unless we are required to retain such information in order to comply with a legal obligation or to establish, exercise or defend legal claims. You may also have to right to request the anonymization, blocking or deletion of unnecessary, excessive or non-conforming personal information.'
            },
            {
                'li_header':'Restriction:',
                'li_body':'You have the right to request that we restrict our processing of your personal information where: you believe such information to be inaccurate, our processing is unlawful, or we no longer need to process such information for a particular purpose, but where we are not able to delete the information due to a legal or other obligation or because you do not want us to delete it.'
            },
            {
                'li_header':'Portability:',
                'li_body':'You have the right to request that we transmit the personal information we hold in respect of you to another data controller, where this is: personal information which you have provided to us; and we are processing that information on the basis of your consent or in order to perform our obligations under contract to you.'
            },
            {
                'li_header':'Objection:',
                'li_body':'Where the legal justification for our processing of your personal information is our legitimate interest, you have the right to object to such processing on grounds relating to your particular situation. We will abide by your request unless we have compelling legitimate grounds for the processing which override your interests and rights, or if we need to continue to process the information for the establishment, exercise or defence of a legal claim. You may also have the right to object at any time to the processing of your personal information for direct marketing purposes, including profiling related to such direct marketing.'
            },
            {
                'li_header':'Withdrawing Consent:',
                'li_body':'If you have consented to our processing of your personal information you have the right to withdraw your consent at any time, free of charge.'
            },
        ],
        note: '',
        footer_desc: 'You can exercise these rights by sending a written request to our data protection contact (see the <a href="#contactus">Contact Us</a> section).'
    }

    const control_tracking_tools = {
        header: 'You Can Control Tracking Tools and Third Party Advertising',
        header_desc: '',
        body: [],
        note: '',
        footer_desc: 
                (
                    `We may use third-party companies and/or media partners to serve ads on our Platform and/or on the sites on which we advertise. We and these third party companies display interest-based advertising using information gathered about you over time across multiple or other platforms. In the course of serving advertisements, these companies may place or recognize a unique cookie on your browser. In addition, we may use web beacons provided by third-party advertising companies to help manage our online advertising. These web beacons enable us to recognize a browser’s cookie when a browser visits this Platform to learn which advertisements bring users to our Platform.
                    <br />
                    To learn more about these third-party companies, cookies and how to "opt-out" of this type of advertising, please go to 
                    <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>
                `)
    }

    const opting_out = {
        header: 'Opting-Out of Receiving our Emails',
        header_desc: '',
        body: [],
        note: '',
        footer_desc: (
            `
                To stop receiving our promotion emails, send a request to our data protection contact (see the Contact Us section) or click on the link provided at the bottom of each email communication. Even if you opt out of receiving promotional messages, we will still send you messages about your questions and purchases.
                <br />
                <br />
                <b>
                You can control tools on your mobile devices
                </b>
                <br />
                For example, you can turn off push notifications from within a mobile app.
            `
        )
    }

    const linked_sites = {
        header: 'Linked Sites',
        header_desc: '',
        body: [],
        note: '',
        footer_desc: (
            `
                Our Platform may contain links to other websites, including social media sites, which may have privacy policies that differ from our own. We are not responsible for the collection, use or disclosure of information collected through third-party websites and expressly disclaim any liability related to such collection, use or disclosure. We are not responsible for any information or content contained on such sites. Links to other websites are provided solely as a convenience. Your browsing, use and interaction on any other websites, including websites which have a link to the Platform, are subject to that website’s own rules and policies. Please review the privacy policy posted on any website that you may access through, or which links to the Platform.
                <br />
                <br />
                Please note that <b>if you provide information to us via a social media site or participate in a social media site linked to the Platform, you consent to our use of your information for any reason in accordance with this Privacy Policy as if it were submitted to us directly via the Platform.</b>
            `
        )
    }

    const security_procedures = {
        header: 'Security Procedures',
        header_desc: '',
        body: [],
        note: '',
        footer_desc: (
            `
                We take reasonable precautions and have implemented reasonable technical measures to protect against the risks of unauthorized access to, erroneous disclosure of, and unlawful interception of personal information submitted via the Platform or otherwise collected or possessed by GCC.
            `
        )
    }

    const changes_updates = {
        header: 'Changes and Updates to the Privacy Policy',
        header_desc: '',
        body: [],
        note: '',
        footer_desc: (
            `
                From time to time, we may revise the Privacy Policy. To help you stay current of any changes, ABI may take one or more of the following steps: (1) GCC notes the date the Privacy Policy was last updated above, and (2) when GCC makes a material change to the Privacy Policy, we may post announcements of such changes on the homepage of the Platform next to the link to this Privacy Policy. Your use of the Platform following the posting of any revised Privacy Policy shall be deemed acceptance of the revised policy, so we strongly recommend that you review the Privacy Policy periodically. If we are required by law to obtain your express consent for any changes to the Privacy Policy, then we will make a commercially reasonable attempt to obtain your consent before implementing such revisions.
            `
        )
    }

    const grievance_officer = {
        header: 'Grievance Officer',
        header_desc: '',
        body: [],
        note: '',
        footer_desc: (
            `
                This page is published and shall be construed in accordance with the provisions of Rule 3(11) of the Information Technology (Intermediaries Guidelines) Rules, 2011 (“IT Rules”) that requires publishing the name of the Grievance Officer and his/her contact details on the website, such that users or any victim who suffers as a result of a violation of Rule 3 (outlined in our Privacy Policy and Terms of Use) by any other person, can notify their complaints to us.
                <br />
                <br />
                Further, as per Rule 3(4) of the IT Rules, the Grievance Officer shall acknowledge or respond to Your communication of violation of Rule 3, by any other person, within 36 (thirty six) hours, and the complaints shall be redressed within 1 (one) month from the date of receipt of the complaint.
                <br />
                <br />
                In accordance with Information Technology Act, 2000 and rules made there under, the name and the contact details of the Grievance Officer are provided:
                <br />
                <br />
                <b>
                Mr. Sumit Kumar Singh.
                </b>
                <br />
                GCC Services India Private Limited,
                <br />
                Address: AB InBev India, 11th, 12th & 13th Floor, Bagmane World Technology Center SEZ, K.R.Puram Marathahalli Ring Road, Laxmi Sagar Layout, Mahadevapura, Bengaluru, Karnataka 560048
                <br />
                Email id: <a href="mailto:info@abinbev-india.com">info@abinbev-india.com</a>
                <br />
                Time: Monday - Friday (9:00 - 18:00)
            `
        )
    }

    const contact_us = {
        header: 'Contact Us',
        header_desc: '',
        body: [],
        note: '',
        footer_desc: (
            `
                GCC has in place a data protection contact point available for you to submit your questions or requests relating to the processing of your personal information. You can access this data protection contact point in our global “contact us” website.Please select the typology “Grievance Officer” in the Contact Us Form.
                <br />
                <br />
                Please contact our data protection contact point or our data protection officer indicated above if you wish to exercise your rights as data subject (see the <a href="https://www.abinbev-india.com/privacy-policy#choices" >Rights section</a> above).Thank you for visiting our site.
            `
        )
    }

    return (
      <div className='privacy_container'>
        <div className='container'>
            <div className='privacy_header'>
                PRIVACY POLICY
            </div>
            <div className='privacy_content'>
                <div className='header'>
                    Contents
                </div>
                <div className='privacy_list'>
                    <a href='#what_we_collect'>What Information We Collect</a>
                    <br />
                    <a href='#how_we_collect'>How We Collect Information</a>
                    <br />
                    <a href='#how_we_use'>How We Use Information</a>
                    <br />
                    <a href='#how_we_share'>How We Share Your Information</a>
                    <br />
                    <a href='#choice_rights'>Your Rights and Choices</a>
                    <br />
                    <a href='#linked_sites'>Linked Sites</a>
                    <br />
                    <a href='#security_procedures'>Security Procedures</a>
                    <br />
                    <a href='#changes_updates'>Changes and Updates to the Privacy Policy</a>
                    <br />
                    <a href='#grievance_officer'>Grievance officer</a>
                    <br />
                    <a href='#contact_us'>Contact Us</a>
                </div>

            </div>
            <div className='privacy_description'>
                Thank you for visiting our website, mobile website, or mobile application (collectively, the "Platform"). This privacy policy (the "Privacy Policy") is designed to assist you in understanding how <b>AB INBEV GCC Services India Private Limited (“ABI or GCC”)</b>, a company incorporated under Companies Act, 2013 adhering to India laws, having its registered office at Peridot Block, 11th,12th &13th floor, Bagmane World Technology Centre SEZ, K. R. Puram, Marathahalli, Ring Road Bangalore - 560048, registered with the Corporate Identification Number: U74900KA2014FTC077722 (“ABI”, “GCC”, “we”, “our” or “us”) collects, uses, and protects any information you may provide to us on the Platforms where it is posted. Our Terms and Conditions, available here describe the terms and conditions governing your access to and use of the Platform.
                <br />
                <b>
                    Your use of the Platform signifies your agreement to be bound by both the Privacy Policy and our Terms and Conditions. If you do not agree to the terms of the Privacy Policy, please do not use the Platform. Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.
                </b>
            </div>

            <PrivacyContentSection data={what_we_collect} id="what_we_collect"/>

            <PrivacyContentSection data={how_we_collect} id="how_we_collect" />

            <PrivacyContentSection data={how_we_use} id="how_we_use" />

            <PrivacyContentSection data={how_we_share} id="how_we_share" />

            <PrivacyContentSection data={choice_rights} id="choice_rights" />

            <PrivacyContentSection data={control_tracking_tools} />

            <PrivacyContentSection data={opting_out} />

            <PrivacyContentSection data={linked_sites} id="linked_sites" />

            <PrivacyContentSection data={security_procedures} id="security_procedures" />

            <PrivacyContentSection data={changes_updates} id="changes_updates" />

            <PrivacyContentSection data={grievance_officer} id="grievance_officer" />

            <PrivacyContentSection data={contact_us} id="contact_us" />
        </div>
      </div>
    );
}

export default PrivacyContent;