import React from "react";
class Heading extends React.Component {
    render() {
        return (
            <div className="common-heading-sub">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <p style={{ marginTop: '20px' }}>AB InBev always dreams big. It’s our culture and our heritage. But more than that, it’s our future. A future that everyone can celebrate and everyone can share. A future with more cheers.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Heading;