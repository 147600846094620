import React, {useState} from "react";
import joanna from '../../../assets/images/GCC_Leaders/joanna.png';
import Modal from 'react-bootstrap/Modal';
class JoannaMoraes extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="profile-details">
                        <div className="image">
                            <img alt="Joanna Moraes" src={joanna} />
                        </div>
                        <div className="details">
                            <h4>Joanna Moraes</h4>
                            <p>D&amp;I Lead, GCC India</p>
                        </div>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={joanna} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Joanna Moraes</h4>
                            <p className="sub-head">D&I Lead, GCC India</p>
                            <p className="content">Joanna Moraes started her journey at AB InBev GCC 5+ years ago as a People Business Partner in the People (HR) Function. Currently, she leads Diversity & Inclusion and Employee Relations, which develops and maintains a positive, diverse, and inclusive working relationship between AB InBev and its people. Her dual roles enable her to ensure that AB InBev's policies or practices reinforce diversity and inclusion at the workplace. She has been instrumental in implementing various gender-neutral and beneficial parental policies. 
                                <br/><br/>During her journey with AB InBev, she has driven CSR initiatives like the Water and Daily Meal program for underprivileged school children and AB InBev's 50 Bed Modular ICU & Hospital in Bangalore. 
                                Joanna's diverse multicultural and professional background has enabled her to believe in the importance of diversity at the workplace. 
                                <br/><br/>She is a proud parent of 4 pets and volunteers at a local Bangalore cat rescue group in her free time. </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default JoannaMoraes;