import React from "react";
import leader_1 from '../../assets/images/diversity_inclusion/leader_1.jpeg';
import leader_2 from '../../assets/images/diversity_inclusion/leader_2.png';


class Our_D_I_Initiatives extends React.Component {
    render() {
        return(
            <div className="diversity-inclusion initiatives-di">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="our-initiatives-heading">OUR <span>D&amp;I</span> INITIATIVES </div>
                            <div className="di-list-table di-list-table-2">
                                <div className="di-list-table-right">
                                    <div className="image images-2">
                                        <img alt="Meeta Agarwal" className="top-img" src={leader_1} />
                                        <img alt="Runa Dhawan" className="btm-img" src={leader_2} />
                                    </div>
                                </div>
                                <div className="di-list-table-left">
                                    <div className="details">
                                        <h2>Crafting <span>Women Leaders</span>
                                        </h2>
                                        <p>Our women's leadership programs are a way to recognize and train women to take up leadership roles. We have tailored initiatives guiding them to be role models and help other women excel. These programs empower careers and enable continuous learning for women at AB InBev. <br /><br /> Our programs aim to develop self-confidence, identify strengths, work on improvement areas, and create visibility and a strong brand by establishing niche skills with a strong peer network.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Our_D_I_Initiatives;