import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import akhil from '../../../assets/images/BU_Leaders/akhil.png';
class AkhilSrivastava extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Alexander Komrakov" src={akhil} />
                    </div>
                    <div className="details">
                        <h4>Akhil Srivastava</h4>
                        <p>Head of Logistics, India & South East Asia</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={akhil} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Akhil Srivastava</h4>
                            <p className="sub-head">Head of Logistics, India & South East Asia</p>
                            <p className="content">Akhil heads Logistics in India and South East Asia at AB InBev and has joined us in 2019. In his current role, Akhil leads end-to-end planning, logistics operations, distribution and retpack management. Additionally, he overlooks the footprint and network design optimization along with digital transformation for establishing the supply chain of the future, incorporating new age interventions, tools and design thinking for transforming operations for increased agility and resilience.<br/><br/>With over 15 years of experience, Akhil is passionate about creating and scaling integrated businesses for success. Akhil possesses strong analytical skills and an insight-driven mindset, which combined with solid strategic communication and managerial skills, has enabled him to lead many data-driven projects, digital programs, and initiatives to propel AB InBev's business in India and South East Asia.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default AkhilSrivastava;