import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../GCCIndia/CSR-Background.png';

const MoreCSR = () => {
  const containerStyle = {
    position: 'relative',
    color: '#000',
    textAlign: 'left',
    marginBottom: '80px',
    paddingLeft: '60px',
    paddingRight: '20px',
  };

  const bodyStyle = {
    margin: '0',
    padding: '0',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '100%',
    backgroundPosition: 'center',
  };

  const divStyle = {
    height: '400px',
    padding: '83px 40px 6px 40px',
    position: 'relative',
  };

  const headerStyle = {
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
    marginLeft: '0px',
  };

  const subHeaderStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
    background: 'linear-gradient(270deg, #EFD315 0%, #E2AF39 98.81%)',
    padding: '2px', // Adjusted padding for equal spacing
    marginBottom: '20px',
    maxWidth: 'fit-content',
  };

  const textContainerStyle = {
    width: '100%',
    maxWidth: '747px',
    fontFamily: 'Avantt',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0em',
    color: 'white',
    marginLeft: '5px',
    marginBottom: '30px',
  };

  const buttonContainerStyle = {
    marginLeft: '5px',
    marginBottom: '30px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: '1px solid white',
    color: 'white',
    textDecoration: 'none',
    transition: 'background-color 0.3s, color 0.3s',
  };

  return (
    <div style={bodyStyle}>
      <div style={containerStyle}>
        <div className="csr-container" style={divStyle}>
          <div style={subHeaderStyle}>CORPORATE SOCIAL RESPONSIBILITY</div>
          <div style={textContainerStyle}>
            <h6 style={headerStyle}>Driving Long-Term Social Impact</h6>
            <p>
              To actively contribute to social and economic development and build a sustainable way of life, our overarching goal is to improve the country's Human Development Index. Our approach is focused on fostering positive change one community at a time.
            </p>
          </div>
          <div style={buttonContainerStyle}>
            <Link to="/bu-social" style={{ textDecoration: 'none' }}>
              <a href="bu-social" className="csr-button" style={buttonStyle}>VIEW OUR CSR INITIATIVES</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreCSR;
