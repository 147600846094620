import React from 'react';
import { Link } from 'react-router-dom';

const NoRouteFoundPopup = () => {
  return (
    <div className="popup">
      <p>No routes found. Click <Link to="/home">here</Link> to go to the homepage.</p>
    </div>
  );
};

export default NoRouteFoundPopup;
