import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import vijoe from '../../../assets/images/GCC_Leaders/vijoe.png';
class VijoeMathew extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div className="col-md-3 col-xs-12 col-sm-3">
                <div className="profile-details" onClick={this.openModal}>
                    <div className="image">
                        <img alt="Vijoe Mathew" src={vijoe} />
                    </div>
                    <div className="details">
                        <h4>Vijoe Mathew</h4>
                        <p>Global Director, Analytics</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="details-modal-left">
                            <img alt="Runa Dhawan" src={vijoe} />    
                        </div>
                        <div className="details-modal-right">
                            <h4>Vijoe Mathew</h4>
                            <p className="sub-head">Global Director, Analytics</p>
                            <p className="content">Vijoe is a seasoned Data Science Leader with over 20 years of expertise in Gen AI, Machine Learning, Deep Learning, IoT, Data Mining, and Analytics Product Development. He holds a Bachelor’s degree in engineering from JNNCE India and a Master’s degree in Industrial Management from KU Leuven, Belgium. Joining AB InBev in 2018 as a Senior Manager in Analytics, he quickly advanced to the role of Analytics Director, spearheading the development of a domain-agnostic advanced Data Science & AI COE in India. His exceptional leadership led to his appointment as Global Director of Analytics in 2022, a testament to his significant contributions to AB InBev. </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default VijoeMathew;