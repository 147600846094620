import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner">
                <div className="common-banner-inner">
                    <h1>OUR <span className="red-text">VALUES</span></h1>
                    <p>DREAM. PEOPLE. CULTURE.</p>
                </div>
            </section>
        );
    }
}
export default Banner;