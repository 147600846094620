import React from "react";
class Heading extends React.Component {
    render() {
        return(
            <div className="common-heading-sub gcc-india-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3>TOGETHER WE CAN <span>ACHIEVE ANYTHING</span></h3>
                            <p>Our business is about bringing people together. Each team across our organisation has an important role to play in helping us succeed and grow. When you join us, you’ll get opportunities to move between them to build the career that really fits your talents and your passion.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Heading;