import React from "react";
import sales from '../../assets/images/BU_India/sales.jpeg';
class Sales extends React.Component {
    render() {
        return(
            <div className="bu-common-bu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="bu-common-main">
                                <div className="bu-right">
                                    <h4>Sales</h4>
                                    <p className="d-none d-sm-block">Our Sales team puts our marketing plans into action, interfaces with our customers, and grows our footprint. This team is the key to getting our brands into the consumer's hands.</p>
                                </div>
                                <div className="bu-left">
                                    <div className="image">
                                        <img alt="Sales" src={sales} />
                                    </div>
                                    <p className="d-block d-sm-none">Our Sales team puts our marketing plans into action, interfaces with our customers, and grows our footprint. This team is the key to getting our brands into the consumer's hands.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Sales;