import React from "react";
import Slider from "react-slick";
import global_mt_programme from '../../assets/images/home/global_mt_programme.png';
import leap_programme from '../../assets/images/home/leap_programme.png';
import sales_visionaries_programme from '../../assets/images/home/sales_visionaries_programme.png';
import supply_mt_programme from '../../assets/images/home/supply_mt_programme.png';
import brew_programme from '../../assets/images/home/brew_programme.png';
class CampusProgrammes extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div className="campus-programmes">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="what-we-do-heading">
                                <h3 className="common-heading explore-heading">EXPLORE OUR <span>CAMPUS PROGRAMMES</span></h3>
                            </div>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12 campus-prog">
                            <div>
                                <Slider {...settings}>
                                    <div>
                                        <div className="campus-list">
                                            <div className="image">
                                                <img src={leap_programme} alt={leap_programme} />
                                            </div>
                                            <div className="details">
                                                <h4>LEAP PROGRAMME</h4>
                                                <p>LEAP is a full-time opportunity for university students. You'll get a chance to develop your skills with training, work on live projects and have exposure to senior leadership. The experience will give you great overview of our culture and also provide you with international exposure.</p>
                                                <a href="/leap-programme">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="campus-list">
                                            <div className="image">
                                                <img src={brew_programme} alt={brew_programme} />
                                            </div>
                                            <div className="details">
                                                <h4>Brew Programme</h4>
                                                <p>Our summer internships lasting two months are a great way to get to know our business – and to get yourself noticed. Work in any function, on a live project within a team. We’ll give you access to training resources, senior managers and company events. Make an impact and it could be the start of a promising career with us.</p>
                                                <a href="/brew-programme">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="campus-list">
                                            <div className="image">
                                                <img src={sales_visionaries_programme} alt={sales_visionaries_programme} />
                                            </div>
                                            <div className="details">
                                                <h4>Sales Visionaries Programme</h4>
                                                <p>This is the ultimate sales programme. Our Sales Visionaries Programme (SVP) will give you the chance to put your commercial instincts to test. It’s an incredible opportunity for you to show off your skills, learn the business, take ownership and deliver results.</p>
                                                <a href="/sales-visinories-programme">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="campus-list">
                                            <div className="image">
                                                <img src={global_mt_programme} alt={global_mt_programme} />
                                            </div>
                                            <div className="details">
                                                <h4>Global Management Traineeship</h4>
                                                <p>Our Global Management Traineeship (GMT) programme is the best induction into the world of business. It fast-tracks the careers of the brightest, most driven university graduates. Through a 18-month rotation, we offer in-depth exposure to our business operations and global projects.</p>
                                                <a href="/global-management-trainee-programme">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="campus-list">
                                            <div className="image">
                                                <img src={supply_mt_programme} alt={supply_mt_programme} />
                                            </div>
                                            <div className="details">
                                                <h4>Supply Management Traineeship</h4>
                                                <p>Our Supply Management Traineeship (SMT) is all about brewing, packaging and delivery. The focus is on improving process and efficiencies in our breweries and our logistics/supply chain – all to help us stay ahead of the pack. It's where you can make a big difference to our impact on the environment and focus on innovation.</p>
                                                <a href="/campus-programmes/supply-management">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CampusProgrammes;