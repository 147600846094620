import React from "react";
class BULocationsList extends React.Component {
    render() {
        return(
            <div className="our-locations">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div className="locations-list-show">
                                <div className="locations-details">
                                    <div className="image">
                                        <iframe allowfullscreen="" height="250px" loading="lazy" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d8395.804967384596!2d77.61722126318276!3d13.043550264473446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bae176f080e691f%3A0xd7ef97e5bc347b69!2sab%20inbev%20manyata%20tech%20park!3m2!1d13.0453256!2d77.6211728!5e0!3m2!1sen!2sin!4v1638469042588!5m2!1sen!2sin" width="100%"></iframe>
                                    </div>
                                    <div className="address">
                                        <h3>AB InBev</h3>
                                        <p>6th Floor, Mfar Building, MS Ramaiah North City, Manayata Tech Park, Nagavara, Bengaluru, Karnataka 560045</p>
                                        <a href="mailto:career.gccindia@ab-inbev.com">CONTACT US</a>
                                    </div>
                                    <div className="map-show">
                                        <div id="map-gcc">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BULocationsList;